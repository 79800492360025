<template>
  <div>
    <div>
      {{ variableItem }}
      <b-row>
        <b-col cols="6">
          <div id="stripoSettingsContainer">Loading...</div></b-col
        >
        <b-col cols="6"> <div id="stripoPreviewContainer"></div></b-col>
      </b-row>
      <button @click="save">save</button>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.loadDemoTemplate(this.initPlugin);
  },
  // Utility methods
  props: {
    value: {
      required: false,
      type: String,
    },
    textFloat: {
      required: false,
      type: String,
    },
    placeholder: {
      required: false,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    pattern: {
      required: false,
      type: String,
    },
    detail: {
      required: false,
      type: String,
    },
    name: {
      required: false,
      type: String,
    },
    isDisplay: {
      required: false,
      type: Boolean,
    },
    rows: {
      required: false,
      type: Number,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    maxLength: {
      required: false,
      type: Number,
    },
    img: {
      required: false,
      type: String,
    },
    v: {
      required: false,
      type: Object,
    },
    variableItem: {
      required: false,
    },
    formMain: {
      required: false,
    },
  },
  data() {
    return {
      editor: null,
      file: "",
      type: {
        all: ["image/jpeg", "image/png", "video/mp4"],
        file: ["image/jpeg", "image/png", "application/pdf"],
        pdf: ["application/pdf"],
        png: ["image/png"],
        image: ["image/jpeg", "image/png"],
        video: ["video/mp4"],
        excel: [
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ],
      },
      error: "",
      hasError: false,
      hasImage: false,
      imgModal: null,
      msgModal: null,
      isSuccess: false,
    };
  },
  methods: {
    save() {
      window.StripoApi.getTemplate(function (html, css) {});
    },
    async loadDemoTemplate(callback) {
      const $ = this;
      this.request(
        "GET",
        "https://raw.githubusercontent.com/ardas/stripo-plugin/master/Public-Templates/Basic-Templates/Empty-Template/Empty-Template.html",
        null,
        function (html) {
          $.request(
            "GET",
            "https://raw.githubusercontent.com/ardas/stripo-plugin/master/Public-Templates/Basic-Templates/Empty-Template/Empty-Template.css",
            null,
            function (css) {
              callback({ html: html, css: css });
            }
          );
        }
      );
    },
    request(method, url, data, callback) {
      var req = new XMLHttpRequest();
      req.onreadystatechange = function () {
        if (req.readyState === 4 && req.status === 200) {
          callback(req.responseText);
        } else if (req.readyState === 4 && req.status !== 200) {
          console.error(
            "Can not complete request. Please check you entered a valid PLUGIN_ID and SECRET_KEY values"
          );
        }
      };
      req.open(method, url, true);
      if (method !== "GET") {
        req.setRequestHeader("content-type", "application/json");
      }
      req.send(data);
    },

    initPlugin(template) {
      const apiRequestData = {
        emailId: "6251361f166bfd9e51e19a03",
        userId: "600a68cbbbc06742e4453517",
        groupId: "test",
      };
      const script = document.createElement("script");
      script.id = "stripoScript";
      script.type = "text/javascript";
      script.src = "https://plugins.stripo.email/static/latest/stripo.js";
      var $ = this;
      script.onload = function () {
        window.Stripo.init({
          settingsId: "stripoSettingsContainer",
          previewId: "stripoPreviewContainer",

          locale: "en",
          html: template.html,
          css: template.css,
          apiRequestData: apiRequestData,
          mergeTags: [
            {
              category: "MailChimp",
              entries: $.variableItem,
            },
          ],
          getAuthToken: function (callback) {
            $.axios
              .post("https://plugins.stripo.email/api/v1/auth", {
                pluginId: "2d866fa0fd8e43dbaff4ffd219c09531",
                secretKey: "60faabfe0dcb43a7bfe016cbd7568830",
              })
              .then(({ data }) => {
                callback(data.token);
              });
          },
        });
      };
      document.body.appendChild(script);
    },
    saveImagetoDb: async function (img) {
      let form = {
        Base64String: img,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      let data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      } else {
        this.errorAlert(data.message);
      }
    },
    getHtml: async function () {
      var html = this.editor.runCommand("gjs-get-inlined-html");
      this.$emit("onDataChange", html);
    },
  },
};
</script>

<style>
.nav-tabs.nav-justified {
  display: block !important;
}
</style>
