import Vue from "vue";
import axios from "axios";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export const state = () => ({
  state: {
    friendGetFriendList: [],
    stateCreateFriendGetFriend: null,
    stateFriendGetFriendDetail: null,
    stateUpdateFriendGetFriend: null,
    customerList: {},
    customerDetailsList: {},
    rewardList: null,
    conditionList: null,
    stateExportCustomer: null,
  },
});

export const mutations = {
  setFriendGetFriendList: async function (state, val) {
    state.friendGetFriendList = val;
  },
  setCreateFriendGetFriend: async function (state, val) {
    state.stateCreateFriendGetFriend = val;
  },
  setFriendGetFriendDetail: async function (state, val) {
    state.stateFriendGetFriendDetail = val;
  },
  setUpdateFriendGetFriend: async function (state, val) {
    state.stateUpdateFriendGetFriend = val;
  },
  setCustomerList: async function (state, val) {
    state.customerList = val;
  },
  setCustomerDetailsList: async function (state, val) {
    state.customerDetailsList = val;
  },
  setRewardTypeList: async function (state, val) {
    state.rewardList = val;
  },
  setConditionList: async function (state, val) {
    state.conditionList = val;
  },
  setExportCustomer: async function (state, val) {
    state.stateExportCustomer = val;
  },
};

export const actions = {
    getFriendGetFriendList: async ({ commit }, payload) => {
        await axios.post(`${process.env.VUE_APP_API}/FriendGetFriend/getlist`, payload).then(
            async (data) => {
                if (data.data.result == 1) {
                commit("setFriendGetFriendList", data.data);
                }
            },
            (error) => {
                const code = parseInt(error.response && error.response.status);
                if (code === 401) {
                commit("setModalAlertLogin", true);
                }
            }
        );
    },
    createFriendGetFriendList: async ({ commit }, payload) => {
        await axios.post(`${process.env.VUE_APP_API}/FriendGetFriend/create`, payload).then(
            async (data) => {
                if (data.data.result == 1) {
                commit("setCreateFriendGetFriend", data.data);
                }
            },
            (error) => {
                const code = parseInt(error.response && error.response.status);
                if (code === 401) {
                commit("setModalAlertLogin", true);
                }
            }
        );
    },
    getFriendGetFriendDetail: async ({ commit }, payload) => {
        await axios.get(`${process.env.VUE_APP_API}/FriendGetFriend/get/${payload}`).then(
            async (data) => {
                if (data.data.result == 1) {
                commit("setFriendGetFriendDetail", data.data);
                }
            },
            (error) => {
                const code = parseInt(error.response && error.response.status);
                if (code === 401) {
                commit("setModalAlertLogin", true);
                }
            }
        );
    },
    updateFriendGetFriendList: async ({ commit }, payload) => {
        await axios.put(`${process.env.VUE_APP_API}/FriendGetFriend/update`, payload).then(
            async (data) => {
                if (data.data.result == 1) {
                commit("setUpdateFriendGetFriend", data.data);
                }
            },
            (error) => {
                const code = parseInt(error.response && error.response.status);
                if (code === 401) {
                commit("setModalAlertLogin", true);
                }
            }
        );
    },
    getCustomerFriendGetFriendList: async ({ commit }, payload) => {
        await axios
          .post(`${process.env.VUE_APP_API}/FriendGetFriend/get_customerlist/${payload.id}`, payload)
          .then(async (data) => {
            commit("setCustomerList", data.data);
          });
    },
    getCustomerDetailsFriendGetFriendList: async ({ commit }, payload) => {
        await axios
          .get(`${process.env.VUE_APP_API}/FriendGetFriend/get_customerdetaillist/${payload.id}/${payload.user_guid}`)
          .then(async (data) => {
            commit("setCustomerDetailsList", data.data);
          });
    },
    getRewardTypeFriendGetFriend: async ({ commit }) => {
      await axios.get(`${process.env.VUE_APP_API}/FriendGetFriend/get_reward_type`).then(
          async (data) => {
              if (data.data.result == 1) {
              commit("setRewardTypeList", data.data);
              }
          },
          (error) => {
              const code = parseInt(error.response && error.response.status);
              if (code === 401) {
              commit("setModalAlertLogin", true);
              }
          }
      );
    },
    getConditionFriendGetFriend: async ({ commit }) => {
      await axios.get(`${process.env.VUE_APP_API}/FriendGetFriend/get_friend_condition`).then(
          async (data) => {
              if (data.data.result == 1) {
              commit("setConditionList", data.data);
              }
          },
          (error) => {
              const code = parseInt(error.response && error.response.status);
              if (code === 401) {
              commit("setModalAlertLogin", true);
              }
          }
      );
    },
    exportCustomer: async ({ commit }, payload) => {
      await axios
        .post(`${process.env.VUE_APP_API}/FriendGetFriend/export/${payload.id}`, payload, {
          responseType: "blob",
        })
        .then(
          (data) => {
            commit("setExportCustomer", data);
          },
          (error) => {
            const code = parseInt(error.response && error.response.status);
          }
        );
  },
};

export default {
  state,
  actions,
  mutations,
};
