import connectAPI from "../connectAPI/connectAPI";

const moduleConnectAPI = {
  state: {
    stateLogin: null,
    statePairAzure: null,
    stateLoginAzure: null,
    stateGetLoginAzureSetting: null,
    // news
    stateNews: null,
    stateNewByID: null,
    stateCreateNews: null,
    stateUpdatetNews: null,
    stateDeleteNews: null,
    stateUploadImage: null,
    // notification
    stateGetNewsType: null,
    stateGetNotificationPerPage: null,
    stateGetNotificationByID: null,
    stateGetNotificationNews: null,
    statePushNotification: null,
    stateCancelNotification: null,
    // banner
    stateGetBanner: null,
    stateGetBannerByID: null,
    stateCreateBanner: null,
    stateUpdateBanner: null,
    stateDeleteBanner: null,
    stateGetRedirectType: null,
    stateGetRedirect: null,
    // product
    stateSearchProduct: null,
    stateGetProductByID: null,
    stateDeleteProduct: null,
    stateCreateProduct: null,
    stateUpdatetProduct: null,
    stateGetSubCategory: null,
    stateGetRelateCategory: null,
    // Sell Channel
    stateSellChannel: null,
    // category
    stateGetAllCategory: null,
    stateGetCategoryByID: null,
    stateGetSubcategory: null,
    stateCreateMainCategory: null,
    stateCreateSubCategory: null,
    stateUpdateMainCategory: null,
    stateUpdateSubCategory: null,
    stateGetProductCategory: null,
  },
  mutations: {
    mutationLogin(state, data) {
      state.stateLogin = data;
    },
    mutationPairAzure(state, data) {
      state.statePairAzure = data;
    },
    mutationLoginAzure(state, data) {
      state.stateLoginAzure = data;
    },
    mutationGetLoginAzureSetting(state, data) {
      state.stateGetLoginAzureSetting = data;
    },
    //news
    mutationGetNews(state, data) {
      state.stateNews = data;
    },
    mutationGetNewByID(state, data) {
      state.stateNewByID = data;
    },
    mutationCreateNews(state, data) {
      state.stateCreateNews = data;
    },
    mutationUpdatetNews(state, data) {
      state.stateUpdatetNews = data;
    },
    mutationDeleteNews(state, data) {
      state.stateDeleteNews = data;
    },
    mutationGetNewsType(state, data) {
      state.stateGetNewsType = data;
    },
    mutationUploadImage(state, data) {
      state.stateUploadImage = data;
    },
    // notification
    mutationGetNotificationByID(state, data) {
      state.stateGetNotificationByID = data;
    },
    mutationGetNotificationPerPage(state, data) {
      state.stateGetNotificationPerPage = data;
    },
    mutationGetNotificationNews(state, data) {
      state.stateGetNotificationNews = data;
    },
    mutationPushNotification(state, data) {
      state.statePushNotification = data;
    },
    mutationCancelNotification(state, data) {
      state.stateCancelNotification = data;
    },
    // banner
    mutationGetBanner(state, data) {
      state.stateGetBanner = data;
    },
    mutationGetBannerByID(state, data) {
      state.stateGetBannerByID = data;
    },
    mutationCreateBanner(state, data) {
      state.stateCreateBanner = data;
    },
    mutationUpdateBanner(state, data) {
      state.stateUpdateBanner = data;
    },
    mutationDeleteBanner(state, data) {
      state.stateDeleteBanner = data;
    },
    mutationGetRedirectType(state, data) {
      state.stateGetRedirectType = data;
    },
    mutationGetRedirect(state, data) {
      state.stateGetRedirect = data;
    },
    // product
    mutationSearchProduct(state, data) {
      state.stateSearchProduct = data;
    },
    mutationGetProductByID(state, data) {
      state.stateGetProductByID = data;
    },
    mutationDeleteProduct(state, data) {
      state.stateDeleteProduct = data;
    },
    mutationCreateProduct(state, data) {
      state.stateCreateProduct = data;
    },
    mutationUpdatetProduct(state, data) {
      state.stateUpdatetProduct = data;
    },
    mutationGetSubCategory(state, data) {
      state.stateGetSubCategory = data;
    },
    mutationGetRelateCategory(state, data) {
      state.stateGetRelateCategory = data;
    },
    // Sell Channel
    mutationSellChannel(state, data) {
      state.stateSellChannel = data;
    },
    // category
    mutationGetAllCategory(state, data) {
      state.stateGetAllCategory = data;
    },
    mutationGetCategoryByID(state, data) {
      state.stateGetCategoryByID = data;
    },
    mutationGetSubcategory(state, data) {
      state.stateGetSubcategory = data;
    },
    mutationCreateMainCategory(state, data) {
      state.stateCreateMainCategory = data;
    },
    mutationCreateSubCategory(state, data) {
      state.stateCreateSubCategory = data;
    },
    mutationUpdateMainCategory(state, data) {
      state.stateUpdateMainCategory = data;
    },
    mutationUpdateSubCategory(state, data) {
      state.stateUpdateSubCategory = data;
    },
    mutationGetProductCategory(state, data) {
      state.stateGetProductCategory = data;
    },
  },
  actions: {
    async ActionLogin(contaxt, data) {
      const dataFromAxios = await connectAPI.Login(data);
      await contaxt.commit("mutationLogin", dataFromAxios);
    },
    async ActionPairAzure(contaxt, data) {
      const dataFromAxios = await connectAPI.PairAzure(data);
      await contaxt.commit("mutationPairAzure", dataFromAxios);
    },
    async ActionLoginAzure(contaxt, data) {
      const dataFromAxios = await connectAPI.LoginAzure(data);
      await contaxt.commit("mutationLoginAzure", dataFromAxios);
    },
    async ActionGetLoginAzureSetting(contaxt, data) {
      const dataFromAxios = await connectAPI.GetLoginAzureSetting(data);
      await contaxt.commit("mutationGetLoginAzureSetting", dataFromAxios);
    },
    // news
    async ActionGetNews(contaxt, data) {
      const dataFromAxios = await connectAPI.GetNews(data);
      await contaxt.commit("mutationGetNews", dataFromAxios);
    },
    async ActionGetNewByID(contaxt, data) {
      const dataFromAxios = await connectAPI.GetNewByID(data);
      await contaxt.commit("mutationGetNewByID", dataFromAxios);
    },
    async ActionCreateNews(contaxt, data) {
      const dataFromAxios = await connectAPI.CreateNews(data);
      await contaxt.commit("mutationCreateNews", dataFromAxios);
    },
    async ActionUpdatetNews(contaxt, data) {
      const dataFromAxios = await connectAPI.UpdatetNews(data);
      await contaxt.commit("mutationUpdatetNews", dataFromAxios);
    },
    async ActionDeleteNews(contaxt, data) {
      const dataFromAxios = await connectAPI.DeleteNews(data);
      await contaxt.commit("mutationDeleteNews", dataFromAxios);
    },
    async ActionGetNewsType(contaxt) {
      const dataFromAxios = await connectAPI.GetNewsType();
      await contaxt.commit("mutationGetNewsType", dataFromAxios);
    },
    async ActionGetNotificationNews(contaxt, data) {
      const dataFromAxios = await connectAPI.GetNotificationNews(data);
      await contaxt.commit("mutationGetNotificationNews", dataFromAxios);
    },
    async ActionUploadImage(contaxt, data) {
      const dataFromAxios = await connectAPI.UploadImage(data);
      await contaxt.commit("mutationUploadImage", dataFromAxios);
    },
    async ActionUploadImageSize(contaxt, data) {
      const dataFromAxios = await connectAPI.UploadImageSize(data);
      return dataFromAxios;
      // await contaxt.commit("mutationUploadImage", dataFromAxios);
    },
    //notification
    async ActionGetNotificationPerPage(contaxt, data) {
      const dataFromAxios = await connectAPI.GetNotificationPerPage(data);
      await contaxt.commit("mutationGetNotificationPerPage", dataFromAxios);
    },
    async ActionGetNotificationByID(contaxt, data) {
      const dataFromAxios = await connectAPI.GetNotificationByID(data);
      await contaxt.commit("mutationGetNotificationByID", dataFromAxios);
    },
    async ActionPushNotification(contaxt, data) {
      const dataFromAxios = await connectAPI.PushNotification(data);
      await contaxt.commit("mutationPushNotification", dataFromAxios);
    },
    async ActionCancelNotification(contaxt, data) {
      const dataFromAxios = await connectAPI.CancelNotification(data);
      await contaxt.commit("mutationCancelNotification", dataFromAxios);
    },
    // banner
    async ActionGetBanner(contaxt, data) {
      const dataFromAxios = await connectAPI.GetBanner(data);
      await contaxt.commit("mutationGetBanner", dataFromAxios);
    },
    async ActionGetBannerByID(contaxt, data) {
      const dataFromAxios = await connectAPI.GetBannerByID(data);
      await contaxt.commit("mutationGetBannerByID", dataFromAxios);
    },
    async ActionCreateBanner(contaxt, data) {
      const dataFromAxios = await connectAPI.CreateBanner(data);
      await contaxt.commit("mutationCreateBanner", dataFromAxios);
    },
    async ActionUpdateBanner(contaxt, data) {
      const dataFromAxios = await connectAPI.UpdateBanner(data);
      await contaxt.commit("mutationUpdateBanner", dataFromAxios);
    },
    async ActionDeleteBanner(contaxt, data) {
      const dataFromAxios = await connectAPI.DeleteBanner(data);
      await contaxt.commit("mutationDeleteBanner", dataFromAxios);
    },
    async ActionGetRedirectType(contaxt) {
      const dataFromAxios = await connectAPI.GetRedirectType();
      await contaxt.commit("mutationGetRedirectType", dataFromAxios);
    },
    async ActionGetRedirect(contaxt, data) {
      const dataFromAxios = await connectAPI.GetRedirect(data);
      await contaxt.commit("mutationGetRedirect", dataFromAxios);
    },
    // product
    async ActionSearchProduct(contaxt, data) {
      const dataFromAxios = await connectAPI.SearchProduct(data);
      await contaxt.commit("mutationSearchProduct", dataFromAxios);
    },
    async ActionGetProductByID(contaxt, data) {
      const dataFromAxios = await connectAPI.GetProductByID(data);
      await contaxt.commit("mutationGetProductByID", dataFromAxios);
    },
    async ActionDeleteProduct(contaxt, data) {
      const dataFromAxios = await connectAPI.DeleteProduct(data);
      await contaxt.commit("mutationDeleteProduct", dataFromAxios);
    },
    async ActionCreateProduct(contaxt, data) {
      const dataFromAxios = await connectAPI.CreateProduct(data);
      await contaxt.commit("mutationCreateProduct", dataFromAxios);
    },
    async ActionUpdatetProduct(contaxt, data) {
      const dataFromAxios = await connectAPI.UpdatetProduct(data);
      await contaxt.commit("mutationUpdatetProduct", dataFromAxios);
    },
    async ActionGetSubCategory(contaxt, data) {
      const dataFromAxios = await connectAPI.GetSubCategory(data);
      await contaxt.commit("mutationGetSubCategory", dataFromAxios);
    },
    async ActionGetRelateCategory(contaxt, data) {
      const dataFromAxios = await connectAPI.GetRelateCategory(data);
      await contaxt.commit("mutationGetRelateCategory", dataFromAxios);
    },
    // Sell Channel
    async ActionSellChannel(contaxt) {
      const dataFromAxios = await connectAPI.SellChannel();
      await contaxt.commit("mutationSellChannel", dataFromAxios);
    },
    // category
    async ActionGetAllCategory(contaxt, data) {
      const dataFromAxios = await connectAPI.GetAllCategory(data);
      await contaxt.commit("mutationGetAllCategory", dataFromAxios);
    },
    async ActionGetCategoryByID(contaxt, data) {
      const dataFromAxios = await connectAPI.GetCategoryByID(data);
      await contaxt.commit("mutationGetCategoryByID", dataFromAxios);
    },
    async ActionCreateMainCategory(contaxt, data) {
      const dataFromAxios = await connectAPI.CreateMainCategory(data);
      await contaxt.commit("mutationCreateMainCategory", dataFromAxios);
    },
    async ActionCreateSubCategory(contaxt, data) {
      const dataFromAxios = await connectAPI.CreateSubCategory(data);
      await contaxt.commit("mutationCreateSubCategory", dataFromAxios);
    },
    async ActionUpdateMainCategory(contaxt, data) {
      const dataFromAxios = await connectAPI.UpdateMainCategory(data);
      await contaxt.commit("mutationUpdateMainCategory", dataFromAxios);
    },
    async ActionUpdateSubCategory(contaxt, data) {
      const dataFromAxios = await connectAPI.UpdateSubCategory(data);
      await contaxt.commit("mutationUpdateSubCategory", dataFromAxios);
    },
    async ActionGetProductCategory(contaxt, data) {
      const dataFromAxios = await connectAPI.GetProductCategory(data);
      await contaxt.commit("mutationGetProductCategory", dataFromAxios);
    },
  },
};

export default moduleConnectAPI;
