import Vue from "vue";

Vue.prototype.convertFilterDate = (filter, form, to) => {
  filter.LastType = store.state.filter.dateType;
  filter[form] = store.state.filter.form;
  filter[to] = store.state.filter.to;

  if (filter.LastType == 1) {
    filter.TypeDateFilter = 3;
    filter[form] = moment().startOf("week").format("YYYY-MM-DD");
    filter[to] = moment().format("YYYY-MM-DD");
  } else if (filter.LastType == 2) {
    filter.TypeDateFilter = 2;
    filter[form] = moment().startOf("month").format("YYYY-MM-DD");
    filter[to] = moment().format("YYYY-MM-DD");
  } else if (filter.LastType == 3) {
    filter.TypeDateFilter = 1;
    filter[form] = moment().startOf("year").format("YYYY-MM-DD");
    filter[to] = moment().format("YYYY-MM-DD");
  } else {
    filter.TypeDateFilter = 0;
    if (filter[form]) filter[form] = moment(filter[form]).format("YYYY-MM-DD");
    if (filter[to]) filter[to] = moment(filter[to]).format("YYYY-MM-DD");
  }
  return filter;
};
Vue.prototype.displayDate = () => {
  let text = "";
  let { dateType, form, to } = store.state.filter;

  return `${moment(form).format("DD MMM YYYY")} - ${moment(to).format(
    "DD MMM YYYY"
  )}`;
};
