import connectAPI from '../connectAPIPrivilegeCode/connectAPI'

const modulePrivilegeCode = {
  state: {
    stateGetPrivilegeCode: null,
    stateGetPrivilegeCodeByID: null,
    stateCreatePrivilegeCode: null,
    stateUpdatePrivilegeCode: null,
    stateGetPrivilegeCondition: null,
    stateGetUsedPrivilegeReport: null,
    stateExportPrivilegeReport: null
  },
  mutations: {
    mutationGetPrivilegeCode(state, data) {
      state.stateGetPrivilegeCode = data
    },
    mutationPrivilegeCodeByID(state, data) {
      state.stateGetPrivilegeCodeByID = data
    },
    mutationCreatePrivilegeCode(state, data) {
      state.stateCreatePrivilegeCode = data
    },
    mutationUpdatePrivilegeCode(state, data) {
      state.stateUpdatePrivilegeCode = data
    },
    mutationGetPrivilegeCondition(state, data) {
      state.stateGetPrivilegeCondition = data
    },
    mutationGetUsedPrivilegeReport(state, data) {
      state.stateGetUsedPrivilegeReport = data;
    },
    mutationExportPrivilegeReport(state, data) {
      state.stateExportPrivilegeReport = data;
    }
  },
  actions: {
    async ActionGetPrivilegeCode(contaxt, data) {
      const dataFromAxios = await connectAPI.GetPrivilegeCodeList(data)
      await contaxt.commit('mutationGetPrivilegeCode', dataFromAxios)
    },
    async ActionGetPrivilegeCodeByID(contaxt, data) {
      const dataFromAxios = await connectAPI.GetPrivilegeCodeByID(data)
      await contaxt.commit('mutationPrivilegeCodeByID', dataFromAxios)
    },
    async ActionCreatePrivilegeCode(contaxt, data) {
      const dataFromAxios = await connectAPI.CreatePrivilegeCode(data)
      await contaxt.commit('mutationCreatePrivilegeCode', dataFromAxios)
    },
    async ActionUpdatePrivilegeCode(contaxt, data) {
      const dataFromAxios = await connectAPI.UpdatePrivilegeCode(data)
      await contaxt.commit('mutationUpdatePrivilegeCode', dataFromAxios)
    },
    async ActionGetPrivilegeCondition(contaxt, data) {
      const dataFromAxios = await connectAPI.GetPrivilegeCondition(data)
      await contaxt.commit('mutationGetPrivilegeCondition', dataFromAxios)
    },
    async ActionGetUsedPrivilegeReport(context, data) {
      const dataFromAxios = await connectAPI.GetUsedPrivilegeReport(data)
      await context.commit('mutationGetUsedPrivilegeReport', dataFromAxios)
    },
    async ActionExportPrivilegeReport(context, data) {
      const dataFromAxios = await connectAPI.ExportPrivilegeReport(data)
      await context.commit('mutationExportPrivilegeReport', dataFromAxios)
    },
  }
}

export default modulePrivilegeCode
