import Vue from "vue";
import axios from "axios";


export const state = () => ({
  state: {
    receiptScanList: [],
    stateCreateReceiptScan: null,
    stateReceiptScanDetail: null,
    stateUpdateReceiptScan: null,
    receiptScanMonitorList: [],
    receiptScanStatusList: [],
    stateReceiptScanStatusDetail: null,
    receiptScanRejectReasonList: [],
    receiptScannerNameList: []
  },
});

export const mutations = {
  setReceiptScanList: async function (state, val) {
    state.receiptScanList = val;
  },
  setCreateReceiptScan: async function (state, val) {
    state.stateCreateReceiptScan = val;
  },
  setReceiptScanDetail: async function (state, val) {
    state.stateReceiptScanDetail = val;
  },
  setUpdateReceiptScan: async function (state, val) {
    state.stateUpdateReceiptScan = val;
  },
  setReceiptScanMonitorList: async function (state, val) {
    state.receiptScanMonitorList = val;
  },
  setReceiptScanStatusList: async function (state, val) {
    state.receiptScanStatusList = val;
  },
  setReceiptScanStatusDetail: async function (state, val) {
    state.stateReceiptScanDetail = val;
  },
  setReceiptScanRejectReasonList: async function (state, val) {
    state.receiptScanRejectReasonList = val;
  },
  setReceiptScanNameList: async function (state, val) {
    state.receiptScannerNameList = val;
  },
};

export const actions = {
  getReceiptScanList: async ({ commit }, payload) => {
    await axios.post(`${process.env.VUE_APP_API}/ReceiptScanner/getlist`, payload).then(
      async (data) => {
        if (data.data.result == 1) {
          commit("setReceiptScanList", data.data);
        }
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  createReceiptScan: async ({ commit }, payload) => {
    await axios.post(`${process.env.VUE_APP_API}/ReceiptScanner/create`, payload).then(
      async (data) => {
        if (data.data.result == 1) {
          commit("setCreateReceiptScan", data.data);
        }
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  getReceiptScanDetail: async ({ commit }, payload) => {
    await axios.get(`${process.env.VUE_APP_API}/ReceiptScanner/get/${payload}`).then(
      async (data) => {
        if (data.data.result == 1) {
          commit("setReceiptScanDetail", data.data);
        }
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  updateReceiptScan: async ({ commit }, payload) => {
    await axios.put(`${process.env.VUE_APP_API}/ReceiptScanner/update`, payload).then(
      async (data) => {
        if (data.data.result == 1) {
          commit("setUpdateReceiptScan", data.data);
        }
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  getReceiptScanMonitorList: async ({ commit }, payload) => {
    await axios.post(`${process.env.VUE_APP_API}/ReceiptScanner/GetReceiptReport/${payload.id}/${payload.status}`, payload).then(
      async (data) => {
        if (data.data.result == 1) {
          commit("setReceiptScanMonitorList", data.data);
        }
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  getReceiptScanStatusList: async ({ commit }) => {
    await axios.get(`${process.env.VUE_APP_API}/ReceiptScanner/GetUserReceiptScannerStatus`).then(
      async (data) => {
        if (data.data.result == 1) {
          commit("setReceiptScanStatusList", data.data);
        }
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  getReceiptScanStatusDetail: async ({ commit }, payload) => {
    await axios.get(`${process.env.VUE_APP_API}/ReceiptScanner/GetUserReceiptScannerDetail/${payload}`).then(
      async (data) => {
        if (data.data.result == 1) {
          commit("setReceiptScanStatusDetail", data.data);
        }
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  getReceiptScanRejectReasonList: async ({ commit }) => {
    await axios.get(`${process.env.VUE_APP_API}/ReceiptScanner/GetUserReceiptScannerStatusDetail`).then(
      async (data) => {
        if (data.data.result == 1) {
          commit("setReceiptScanRejectReasonList", data.data);
        }
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  updateReceiptScanStatus: async ({ commit }, payload) => {
    await axios.post(`${process.env.VUE_APP_API}/ReceiptScanner/UpdateReceiptScanner`, payload).then(
      async (data) => {
        if (data.data.result == 1) {
          commit("setReceiptScanStatusDetail", data.data);
        }
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  getReceiptScanNameList: async ({ commit }) => {
    await axios.get(`${process.env.VUE_APP_API}/ReceiptScanner/GetReceiptScannerNameList`).then(
      async (data) => {
        if (data.data.result == 1) {
          commit("setReceiptScanNameList", data.data);
        }
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
};
export default {
  state,
  actions,
  mutations,
};
