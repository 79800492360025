<template>
    <div v-if="isVisible" class="externalPreviewPopup ">
      <div class="modal-container container">
        <div class="modal-header-container">
          <div>
            <button type="button" class="close modal-close-button" @click="closePopup">
              <span>×</span>
            </button>
            <h4 class="modal-title">Preview</h4>
          </div>
        </div>
        <div id="content" style="padding: 15px;" class="container-fluid p-3 ">
          <b-row class="justify-content-center">
            <b-col cols="8">
              <div class="esdev-desktop-device">
                <div class="esdev-email-window-panel">
                  <div class="esdev-email-subject" style="min-height: 20px"></div>
                </div>
                <div class="esdev-desktop-device-screen">
                  <iframe id="iframeDesktop"  frameborder="0" width="100%" height="500"></iframe>
                </div>
              </div>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-center">
              <div class="esdev-mobile-device center-block">
                <div class="esdev-mobile-device-screen">
                  <img src="mobile-view-top-bar.png" alt="">
                  <iframe id="iframeMobile" frameborder="0" width="100%"  @load="onMobileIframeLoaded"></iframe>
                  <img class="esdev-mail-bottom-bar" src="mobile-view-bottom-bar.png" alt="">
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      htmlContent: {
        type: String,
        required: false
      },
      isPdpa: {
        type:Boolean,
        required: false
      }
    },
    data() {
      return {
        isVisible: false
      };
    },
    methods: {
      openPopup() {
        this.isVisible = true;
        this.$nextTick(() => {
          this.updateContent(this.htmlContent);
        });
      },
      closePopup() {
        this.isVisible = false;
        if(this.isPdpa){
        document.documentElement.style.overflow = "auto";
        }
      },
      onMobileIframeLoaded() {
        const frameDocument = document.getElementById('iframeMobile').contentDocument;
        frameDocument.documentElement.style.setProperty('overflow-x', 'visible', 'important');
        frameDocument.body.style.setProperty('overflow-y', 'scroll', 'important');
        frameDocument.body.style.setProperty('visibility', 'visible', 'important');
  
        const isAdaptive = !frameDocument.documentElement.querySelector('.gmail-fix');
  
        if (!isAdaptive) {
          const clientWidth = frameDocument.documentElement.clientWidth;
          const scrollWidth = frameDocument.documentElement.scrollWidth;
          const scale = (clientWidth / scrollWidth).toFixed(2);
  
          frameDocument.documentElement.style.setProperty('height', '100%', 'important');
          frameDocument.documentElement.style.setProperty('transform', `scale(${scale})`, 'important');
          frameDocument.documentElement.style.setProperty('transform-origin', 'top left', 'important');
          frameDocument.body.style.setProperty('overflow-x', 'hidden', 'important');
        }
      },
      updateContent(html) {
        const iframeDesktop = document.querySelector('#iframeDesktop');
        if (iframeDesktop) {
          iframeDesktop.contentWindow.document.open('text/html', 'replace');
          iframeDesktop.contentWindow.document.write(html);
          iframeDesktop.contentWindow.document.close();
        }
  
        const iframeMobile = document.querySelector('#iframeMobile');
        if (iframeMobile) {
          iframeMobile.contentWindow.document.open('text/html', 'replace');
          iframeMobile.contentWindow.document.write(html);
          iframeMobile.contentWindow.document.close();
        }
      }
    }
  };
  </script>
  
  <style>
.externalPreviewPopup {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    font-family: sans-serif;
}

#iframeMobile{
  height: 459px !important;
}

.modal-container {
    background-color: #f6f6f6;
    border-radius: 17px 17px 30px 30px;
    width: 80%;
}

.modal-header-container {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
}

.modal-title {
    color: rgb(85, 85, 85);
}

::v-deep iframe{
  height: 70vh !important
}

.modal-close-button {
    cursor: pointer;
    background: transparent;
    border: 0;
    float: right;
    font-size: 21px;
    font-weight: bold;
    opacity: .2;
}

*, ::after, ::before {
    box-sizing: border-box;
}


.esdev-desktop-device-screen,
.esdev-mobile-device-screen {
    background-color: #ffffff;
    height: 70vh
}

.esdev-desktop-device {
    background: #ffffff;
    border: 2px solid #e8e8e8;
    font-size: 0;
    position: relative;
    border-radius: 10px 10px 3px 3px;
}

.esdev-desktop-device:before {
    content: "―–";
    display: block;
    background: #e8e8e8;
    position: absolute;
    margin-top: 16px;
    border-radius: 10px;
    margin-left: 47px;
    font-size: 73px;
    color: #e8e8e8;
    line-height: 0;
    font-family: sans-serif;
}

.esdev-email-window-panel {
    padding: 15px 15px 12px;
    border-bottom: 1px solid #e8e8e8;
    font-size: 14px;
}

.esdev-email-user-avatar {
    float: left;
    color: #e8e8e8 !important;
    text-shadow: none !important;
    font-size: 16px !important;
}

.esdev-email-subject {
    padding-left: 13px;
    padding-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.esdev-mobile-device {
    position: relative;
    height: 698px;
    width: 344px;
    border-radius: 40px;
    padding: 50px 10px 60px;
    background: #fff;
    border: 2px solid #e8e8e8;
}

.esdev-mobile-device-screen {
    box-shadow: 0 0 0 1px #e8e8e8;
    border-radius: 2px;
    overflow: auto;
    height: 568px;
}

.esdev-mobile-device-screen .esdev-mail-bottom-bar {
    margin-top: -5px;
}

.esdev-mobile-device:after {
    position: absolute;
    bottom: 13px;
    left: 50%;
    margin-left: -24px;
    width: 50px;
    height: 50px;
    border-radius: 30px;
    border: 2px solid #e8e8e8;
    content: " ";
    display: block;
}

.esdev-mobile-device:before {
    position: absolute;
    top: 22px;
    left: 50%;
    margin-left: -30px;
    width: 60px;
    background: #e8e8e8;
    height: 8px;
    border-radius: 20px;
    content: " ";
    display: block;
}


@media (max-width: 1600px) {
 .esdev-mobile-device {
    position: relative;
    height: 608px !important;
    width: 344px;
    border-radius: 40px;
    padding: 50px 10px 60px;
    background: #fff;
    border: 2px solid #e8e8e8;
}

.esdev-mobile-device-screen {
    box-shadow: 0 0 0 1px #e8e8e8;
    border-radius: 2px;
    overflow: auto;
    height: 480px !important;
}

}
  </style>
  