import Vue from "vue";
import axios from "axios";
import apiPath from "@/plugins/api";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export const state = () => ({
  state: {
    modalAlertLogin: false,
    extraPointList: [],
    extraPointDetail: [],
    resCreateExtraPoint: [],
    resUpdateExtraPoint: [],
  },
});

export const mutations = {
  setModalAlertLogin: async function (state, val) {
    state.modalAlertLogin = val;
  },
  setExtraPointList: async function (state, val) {
    state.extraPointList = val;
  },
  setExtraPointDetail: async function (state, val) {
    state.extraPointDetail = val;
  },
  setResCreateExtraPoint: async function (state, val) {
    state.resCreateExtraPoint = val;
  },
  setResUpdateExtraPoint: async function (state, val) {
    state.resUpdateExtraPoint = val;
  },
};

export const actions = {
  getFieldProfiletype: async ({ commit }) => {
    await axios.get(`${apiPath}/Form/fieldprofiletype`).then(
      async (data) => {
        commit("setProfileList", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  getExtraPointList: async ({ commit }, payload) => {
    await axios.get(`${apiPath}/extrapoint${payload}`).then(
      async (data) => {
        commit("setExtraPointList", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  getExtraPointDetail: async ({ commit }, payload) => {
    await axios.get(`${apiPath}/extrapoint/${payload}`).then(
      async (data) => {
        commit("setExtraPointDetail", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  createExtraPoint: async ({ commit }, payload) => {
    await axios.post(`${apiPath}/extrapoint`, payload).then(
      async (data) => {
        commit("setResCreateExtraPoint", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  updateExtraPoint: async ({ commit }, payload) => {
    await axios.put(`${apiPath}/extrapoint`, payload).then(
      async (data) => {
        commit("setResUpdateExtraPoint", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
};

export default {
  state,
  actions,
  mutations,
};
