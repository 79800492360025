import Vue from "vue";
import Vuex from "vuex";
import moduleConnectAPI from "./connectAPI/index";
import coupon from "./modules/coupon";
import moduleSalesChannel from "./connectAPISalesChannel/index";
import stampcard from "./modules/stampcard";
import modulePrivilegeCode from "./connectAPIPrivilegeCode/index";
import form from "./modules/form";
import extraPoint from "./modules/extraPoint";
import allRightRequest from "./modules/allrightrequest";
import permission from "./modules/permission";
import moduleVideo from "./connectAPIVideo/index";
import automation from "./modules/automation";
import importFile from "./modules/importFile";
import memberTier from "./modules/memberTier";
import theme from "./modules/theme";
import redeem from "./modules/redeem";
import report from "./modules/report";
import booking from "./modules/booking";
import product from "./modules/product";
import setting from "./modules/setting";
import activitylog from "./modules/activitylog";
import segment from "./modules/segment";
import friendgetfriend from "./modules/friendgetfriend";
import receiptscan from "./modules/receiptscan";
import { state, actions, mutations, getters } from "./modules/syncFilter";
import { axios as $axios, instance as axios } from "../services/axios";
Vue.use(Vuex);

export const store = new Vuex.Store({
  namespaced: true,
  state: {
    formatDateNew: "DD/MM/YYYY",
    formatDate: "DD MMM YYYY",
    formatDateTime: "DD MMM YYYY HH:mm",
    formatTime: "HH:mm",
    screenSize: {
      width: 0,
      height: 0,
    },
    province: [],
    countryCode: [],
    chartColor: {
      isLoading: false,
      color: [
        "#8F20C6",
        "#8146CE",
        "#7e60dc",
        "#6b79e2",
        "#5597e5",
        "#5CB1E5",
        "#5ABCD4",
        "#27BDA7",
      ],
    },
    languageList: [],
    currentLanguage: "1",
    ...state,
  },
  getters: {
    ...getters,
  },
  mutations: {
    setScreenSize(state, screenSize) {
      state.screenSize.width = screenSize.width;
      state.screenSize.height = screenSize.height;
    },
    setProvince(state, val) {
      state.province = val;
    },
    setCountrycode(state, val) {
      state.countryCode = val;
    },
    setChartColor(state, val) {
      state.chartColor = { isLoading: true, data: val };
    },
    setLanguageList(state, val) {
      state.languageList = val;
    },
    setCurrentLanguage(state, val) {
      state.currentLanguage = val;
    },
    ...mutations,
  },
  actions: {
    clearFilter: async ({ commit, state }) => {},
    getProvince: async ({ commit, state }) => {
      if (state.province.length == 0) {
        const resp = await $axios(
          `https://api-address-dev.dosetech.co/api/address/form/province`
        );

        commit("setProvince", resp.data);
        return resp.data;
      }
      return state.province;
    },
    getChartColorList: async ({ commit, state }) => {
      if (state.chartColor.length == 0) {
      }
      return state.chartColor;
    },
    getCountryCode: async ({ commit, state }) => {
      if (state.countryCode.length == 0) {
        const resp = await axios("/setting/GetTelephoneCountry");

        commit("setCountrycode", resp);
        return resp;
      }
      return state.countryCode;
    },
    getChartColor: async ({ commit, state }, payload) => {
      var index = 0,
        fade = false;
      if (typeof payload == "object") {
        index = payload.index;
        fade = payload.fade;
      } else {
        index = payload;
      }
      function fadeColor(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
          ? `rgba(${parseInt(result[1], 16)},${parseInt(
              result[2],
              16
            )},${parseInt(result[3], 16)},0.5)`
          : null;
      }

      if (index >= state.chartColor.length) {
        let convertIndex = index - state.chartColor.length;
        return fadeColor(state.chartColor.data.fade[index]);
      }
      if (fade) return fadeColor(state.chartColor.data.fade[index]);
      return state.chartColor.data.color[index];
    },
    getChartColorList: async ({ commit, state }, payload) => {
      const result = await axios("/setting/chartcolor");
      let color = { color: [], fade: [] };
      for (const element of Object.keys(result.data.detail)) {
        if (element.includes("fade"))
          color.fade.push(result.data.detail[element]);
        else color.color.push(result.data.detail[element]);
      }

      commit("setChartColor", color);
    },
    getLanguageList: async ({ commit, state }, payload) => {
      if (state.languageList.length == 0) {
        const resp = await axios("setting/display_language");
        commit("setLanguageList", resp);
        return resp;
      }
      return state.languageList;
    },

    ...actions,
  },
  modules: {
    moduleConnectAPI,
    coupon,
    moduleSalesChannel,
    stampcard,
    modulePrivilegeCode,
    form,
    extraPoint,
    allRightRequest,
    moduleVideo,
    automation,
    importFile,
    memberTier,
    theme,
    redeem,
    report,
    booking,
    product,
    setting,
    activitylog,
    permission,
    segment,
    friendgetfriend,
    receiptscan,
  },
});
