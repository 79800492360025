const Banner = {
  path: "",
  name: "",
  redirect: "/banner",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/banner",
      redirect: "/banner",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Banner",
      meta: {
        requiresAuth: true,
        activeUrl: "/banner",

        parent: "banner",
        lv: 1,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/banner/Index"),
          meta: {
            requiresAuth: true,
            activeUrl: "/banner",
            parent: "banner",

            lv: 2,
          },
        },
        {
          path: "details/:id",
          name: "Banner Detail",
          component: () => import("@/views/pages/banner/Details"),
          meta: {
            requiresAuth: true,
            isChildren: true,
            activeUrl: "/banner",
            parent: "banner",

            lv: 3,
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};

export default Banner;
