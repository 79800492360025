<template>
  <b-modal
    visible
    size="lg"
    hide-header
    centered
    hide-footer
    no-close-on-backdrop
    body-class="bg-transparent"
    content-class="hide-line"
  >
    <div class="card-image-overlay">
      <div class="card-image-panel">
        <font-awesome-icon
          @click="$emit('close')"
          icon="times-circle"
          class="close-icon"
        ></font-awesome-icon>

        <div class="p-3">
          <template v-if="type == 'pdf'">
            <!-- {{ img }} -->
            <pdf :src="img" style="width: 60vw"> </pdf>
          </template>
          <b-img v-else :src="img" alt="id card image"></b-img>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import pdf from "vue-pdf";
export default {
  components: {
    pdf,
  },
  props: {
    img: {
      required: true,
    },
    type: {
      required: false,
      default: "img",
    },
  },
  data() {
    return {
      isTransfersModal: true,
    };
  },
};
</script>

<style lang="scss">
.card-image-overlay {
  // position: fixed;
  // top: 0;
  // width: 100%;
  // height: 100vh;
  // left: 0;
  // z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: rgba(0, 0, 0, 0.39);
  .card-image-panel {
    // max-width: 60vw;
    width: fit-content;
    text-align: right;
    img {
      height: auto;
      width: 100%;
      // aspect-ratio: 16/9;
      //   object-fit: contain;
    }
  }
}
.close-icon {
  font-size: 16px;
  cursor: pointer;
  color: white;
}
.hide-line {
  border: unset !important;
  background: transparent;
}
.bg-transparent {
  background-color: transparent;
}
</style>
