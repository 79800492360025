const Booking = {
  path: "",
  name: "",
  redirect: "/booking",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/booking",
      redirect: "/booking",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Booking",
      meta: {
        requiresAuth: true,
        activeUrl: "/booking",
        parent: "/booking",

        lv: 1,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/booking"),
          meta: {
            requiresAuth: true,
            activeUrl: "/booking",
            parent: "/booking",

            lv: 2,
          },
        },
        {
          path: "details/:id",
          name: "Booking Detail",
          component: () => import("@/views/pages/booking/detail"),
          meta: {
            requiresAuth: true,
            isChildren: true,
            activeUrl: "/booking",
            parent: "/booking",

            lv: 3,
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
export default Booking;
