<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="alertModal"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      id="modal-loading"
    >
      <div class="modal-header" v-if="hasClose">
        <button type="button" aria-label="Close" class="close" @click="hide">
          ×
        </button>
      </div>
      <div class="text-center">
        <img src="@/assets/icons/loading.svg" alt="loading" class="mb-3 img" />
        <!-- <p class="text-text">please wait...</p> -->
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      required: false,
      type: String,
    },
    hasClose: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
.modal-header {
  border: 0 !important;
  padding: 0;
}

.text-text {
  color: #16274a;
  margin-bottom: 0;
  font-size: 26px;
}

.img {
  width: 100px;
}

.modal-body {
  margin: auto;
  text-align: center;
}

::v-deep #modal-loading .modal-content {
  /* background-clip: padding-box; */
  border: unset;
  border-radius: 0.3rem;
  outline: 0;
  background-color: unset;
}
</style>
