import Vue from "vue";
import axios from "axios";

export const state = () => ({
  state: {
    segmentList: [],
    stateCreateSegment: null,
    stateSegmentDetail: null,
    stateUpdateSegment: null,
    stateDeleteSegment: null,
    customerList: {},
  },
});

export const mutations = {
  setSegmentList: async function (state, val) {
    state.segmentList = val;
  },
  setCreateSegment: async function (state, val) {
    state.stateCreateSegment = val;
  },
  setSegmentDetail: async function (state, val) {
    state.stateSegmentDetail = val;
  },
  setUpdateSegment: async function (state, val) {
    state.stateUpdateSegment = val;
  },
  setDeleteSegment: async function (state, val) {
    state.stateDeleteSegment = val;
  },
  setCustomerList: async function (state, val) {
    state.customerList = val;
  },
};

export const actions = {
  getSegmentList: async ({ commit }, payload) => {
    await axios
      .post(`${process.env.VUE_APP_API}/customer/GetSegmentList`, payload)
      .then(
        async (data) => {
          if (data.data.result == 1) {
            commit("setSegmentList", data.data);
          }
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  createSegmentList: async ({ commit }, payload) => {
    await axios
      .post(`${process.env.VUE_APP_API}/customer/createsegment`, payload)
      .then(
        async (data) => {
          if (data.data.result == 1) {
            commit("setCreateSegment", data.data);
          }
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getSegmentDetail: async ({ commit }, payload) => {
    await axios
      .get(`${process.env.VUE_APP_API}/customer/getsegmentbyid/${payload}`)
      .then(
        async (data) => {
          if (data.data.result == 1) {
            commit("setSegmentDetail", data.data);
          }
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  updateSegmentList: async ({ commit }, payload) => {
    return await axios
      .put(`${process.env.VUE_APP_API}/customer/updatesegment`, payload)
      .then(
        async (data) => {
          if (data.data.result == 1) {
            commit("setUpdateSegment", data.data);
          }
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
          return error.response;
        }
      );
  },
  deleteSegment: async ({ commit }, payload) => {
    await axios
      .delete(`${process.env.VUE_APP_API}/customer/delete/${payload}`)
      .then(
        async (data) => {
          commit("setDeleteSegment", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getCustomerSegmentList: async ({ commit }, payload) => {
    await axios
      .post(
        `${process.env.VUE_APP_API}/customer/GetCustomerSegmentList/${payload.id}`,
        payload
      )
      .then(async (data) => {
        commit("setCustomerList", data.data);
      });
  },
};

export default {
  state,
  actions,
  mutations,
};
