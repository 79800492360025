import axios from "axios";
import apiPath from "@/plugins/api";

export default {
  async GetVideoList(form) {
    try {
      var response = await axios.post(
        `${apiPath}/Video/list`,
        form
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async GetVideoByID(id) {
    try {
      var response = await axios.get(`${apiPath}/Video/${id}`);
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async GetVideoMainCategory() {
    try {
      var response = await axios.get(
        `${apiPath}/video/category`
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async GetVideoSubCategory(form) {
    try {
      var response = await axios.post(
        `${apiPath}/video/subcategory`,
        form
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async CreateVideo(form) {
    try {
      var response = await axios.post(`${apiPath}/Video`, form);
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async UpdateVideo(form) {
    try {
      var response = await axios.put(
        `${apiPath}/Video/${form.id}`,
        form
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async DeleteVideo(id) {
    try {
      var response = await axios.delete(
        `${apiPath}/Video/${id}`
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
};
