const Promotions = {
  path: "",
  redirect: "/promotions",
  name: "",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/promotions",
      redirect: "/promotions",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Promotions",
      meta: {
        requiresAuth: true,
        activeUrl: "/promotions",

        lv: 1,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/promotions"),
          meta: {
            requiresAuth: true,
            activeUrl: "/promotions",

            lv: 2,
          },
        },
        {
          path: "promotions-sap-discount/:id",
          name: "Promotions Detail",
          component: () => import("@/views/pages/promotions/detail.vue"),
          meta: {
            requiresAuth: true,
            isChildren: true,
            activeUrl: "/promotions",

            lv: 3,
          },
        },
        {
          path: "promotions-items-discount/:id",
          name: "Items Discount Detail",
          component: () => import("@/views/pages/promotions/itemsDiscount.vue"),
          meta: {
            requiresAuth: true,
            isChildren: true,
            activeUrl: "/promotions",

            lv: 3,
          },
        },
        {
          path: "promotions-header-discount/:id",
          name: "Header Discount Detail",
          component: () =>
            import("@/views/pages/promotions/headerDiscount.vue"),
          meta: {
            requiresAuth: true,
            isChildren: true,
            activeUrl: "/promotions",

            lv: 3,
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
export default Promotions;
