const ProductCollection = {
    path: "",
    name: "",
    redirect: "/product-collection",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "/product-collection",
        redirect: "/product-collection",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        name: "Product Collection",
        meta: {
          requiresAuth: true,
          activeUrl: "/product-collection",
          parent: "/product-collection",
  
          lv: 1,
        },
        children: [
          {
            path: "",
            name: "",
            component: () => import("@/views/pages/productCollection"),
            meta: {
              requiresAuth: true,
              activeUrl: "/product-collection",
              parent: "/product-collection",
  
              lv: 2,
            },
          },
          {
            path: "detail/:id",
            name: "Product Collection Detail",
            component: () =>
              import("@/views/pages/productCollection/detail/CollectionDetail"),
            meta: {
              requiresAuth: true,
              isChildren: true,
              activeUrl: "/product-collection",
              parent: "/product-collection",
  
              lv: 3,
            },
          },
        ],
      },
    ],
    meta: { requiresAuth: true },
  };
  export default ProductCollection;
  