import axios from "axios";
import apiPath from "@/plugins/api";
export const state = () => ({
  state: {
    modalAlertLogin: false,
    themeLogo: {},
    Logo: "",
  },
});

export const mutations = {
  setTheme: async function (state, val) {
    state.themeLogo = val;
    state.Logo = val.themeLogoDomain + val.result.themeLogoHomePage;
  },
};

export const actions = {
  getTheme: async ({ commit }) => {
    await axios.get(`${apiPath}/Setting/theme`).then(
      async (data) => {
        commit("setTheme", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
};
export const getters = {
  theme(state) {
    return state.themeLogo;
  },
  LogoHeader(state) {
    return state.Logo;
  },
};
export default {
  state,
  actions,
  mutations,
  getters,
};
