const CampaignForms = {
  path: "",
  name: "",
  redirect: "/form",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  meta: { requiresAuth: true },
  children: [
    {
      path: "/form",
      name: "Campaign Forms",
      redirect: "/form",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      meta: {
        requiresAuth: true,

        activeUrl: "/form",
        isChildren: true,
        lv: 2,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/form"),
          meta: {
            requiresAuth: true,
            activeUrl: "/form",
            parent: "form",

            lv: 2,
          },
        },
        {
          path: "detail/:id",
          name: "Campaign Forms Details",
          component: () => import("@/views/pages/form/detail/Forms"),
          meta: {
            requiresAuth: true,

            isChildren: true,
            activeUrl: "/form",
          },
        },

        {
          path: "/campaign/detail/:id",
          name: "Campaign",
          component: () => import("@/views/pages/form/report/Campaign"),
          meta: {
            requiresAuth: true,

            isChildren: true,
            activeUrl: "/form",
          },
        },
      ],
    },
  ],
};
export default CampaignForms;
