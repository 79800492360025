<template>
  <div :class="['select-custom', { error: isValidate }]" v-if="!isLoading">
    <label v-if="title" class="">
      {{ title }}
      <span v-if="isRequired" class="text-danger">*</span>
    </label>
    <br v-if="title" />

    <b-form-select
      :name="name"
      :size="size"
      v-bind:value="value"
      :required="required"
      :class="['btn-select']"
      @change="onDataChange"
      :valueField="valueField"
      :textField="textField"
      :disabled="disabled"
    >
      <!-- <template #first>
        <slot name="option-first"></slot>
      </template> -->

      <b-form-select-option v-if="value === 0" disabled :value="0"
        >--- Please Select Value ---</b-form-select-option
      ><b-form-select-option v-else-if="value === null" disabled :value="null"
        >--- Please Select Value ---</b-form-select-option
      ><b-form-select-option v-else value="" disabled selected
        >--- Please Select Value ---</b-form-select-option
      >
      <b-form-select-option
        :value="opt[valueField]"
        v-for="opt of options"
        :key="opt[valueField]"
        :disabled="opt.disabled"
        >{{ opt[textField] }}</b-form-select-option
      >
    </b-form-select>
    <div v-if="v && v.$error" class="mt-1">
      <span class="text-error" v-if="v.required == false">Please select.</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: false,
      type: String,
    },
    text: {
      required: false,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    name: {
      required: false,
      type: String,
    },
    size: {
      required: false,
      type: String,
    },
    options: {
      required: true,
    },
    value: {
      required: false,
      type: Number | String,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    v: {
      required: false,
      type: Object,
    },
    valueField: {
      required: false,
      type: String,
    },
    textField: {
      required: false,
      type: String,
    },
    disabled: {
      required: false,
      type: Boolean,
    },
    noPleaseSelect: {
      required: false,
      type: Boolean,
    },
    indexCondition: {
      required: false,
      type: Number,
    },
    indexGroup: {
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      selected: 0,
      isLoading: true,
    };
  },
  mounted() {
    this.isLoading = false;
  },
  methods: {
    onDataChange(event) {
      let value = this.options.find((option) => option.id === event);

      if (value) {
        this.$emit(
          "onDataChange",
          event,
          this.indexCondition,
          value.name,
          this.indexGroup
        );
      } else {
        this.$emit(
          "onDataChange",
          event,
          this.indexCondition,
          "",
          this.indexGroup
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select-custom.error > .btn-select {
  border-color: red !important;
}
</style>
