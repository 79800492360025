<template>
  <div class="position-relative mb-3">
    <div :class="['text-editor-custom', { error: isValidate }]">
      <label>
        {{ textFloat }}
        <span v-if="isRequired" class="text-danger">*</span>
      </label>

      <vue-editor
        v-model="editContent"
        useCustomImageHandler
        @image-added="handleImageAdded"
      ></vue-editor>
    </div>
    <img :src="img" alt="logo-lang" v-if="img" class="logo-lang" />
    <div v-if="v && v.$error" class="mt-1">
      <span class="text-error" v-if="v.required == false"
        >Please fill out.</span
      >
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  name: "text-editor",
  components: { VueEditor },
  props: {
    textFloat: {
      required: true,
      type: String,
    },
    placeholder: {
      required: true,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    pattern: {
      required: false,
      type: String,
    },
    detail: {
      required: false,
      type: String,
    },
    name: {
      required: false,
      type: String,
    },
    value: {
      required: false,
      type: String,
    },
    isDisplay: {
      required: false,
      type: Boolean,
    },
    rows: {
      required: false,
      type: Number,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    maxLength: {
      required: false,
      type: Number,
    },
    img: {
      required: false,
      type: String,
    },
    v: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      // htmlForEditor: this.value,
      editContent: "",
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image", "code-block"],
      ],
    };
  },
  watch: {
    editContent(value) {
      return this.$emit("onDataChange", value);
    },
    value(val) {
      return (this.editContent = val);
    },
  },

  mounted() {
    this.editContent = this.value;
  },
  methods: {
    handleImageAdded: async function (
      file,
      Editor,
      cursorLocation,
      resetUploader
    ) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        var form = {
          Base64String: reader.result,
        };
        await this.$store.dispatch("ActionUploadImage", form);
        var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
        if (data.result == 1) {
          Editor.insertEmbed(cursorLocation, "image", data.detail);
          resetUploader();
          // this.image.insert(
          //   data.detail,
          //   false,
          //   null,
          //   this.image.get(),
          //   response
          // );
          // return data.detail;
        }
        // return reader.result;
      };
      // var form = {
      //   Base64String: "data:image/png;base64," + blobInfo.base64(),
      // };
      // await this.$store.dispatch("ActionUploadImage", form);
      // var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      // axios({
      //   url: "https://fakeapi.yoursite.com/images",
      //   method: "POST",
      //   data: formData,
      // })
      //   .then((result) => {
      //     const url = result.data.url; // Get url from response
      //     Editor.insertEmbed(cursorLocation, "image", url);
      //     resetUploader();
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
  },
};
</script>

<style scoped>
.text-editor-custom {
  color: var(--font-main-color);
  font-size: 14px;
  margin-bottom: 2px;
}
/* .text-editor-custom.error {
  border: 2px solid red !important;
} */
.text-error {
  color: red;
}
::v-deep .error #quill-container {
  border-top: 1px;
  border-color: red;
}
</style>
