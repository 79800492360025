<template>
  <div>
    <div class="main-label">{{ textFloat }}</div>
    <b-dropdown
      :id="`dropdown-${name}`"
      block
      variant="dropdown-checkbox"
      menu-class="w-100"
      no-caret
    >
      <template #button-content>
        <div class="d-flex justify-content-between align-items-cente">
          <p class="display-select">{{ displaySelect }}</p>
          <font-awesome-icon icon="chevron-down" />
        </div>
      </template>
      <b-dropdown-form>
        <b-form-group>
          <template #label>
            <b-form-checkbox
              @change="onDataChangeCheckAll"
              :checked="valueSelectedAll"
              :aria-describedby="name"
              :aria-controls="name"
            >
              Select All
            </b-form-checkbox>
          </template>
          <template v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              :id="`checkbox-${name}`"
              :checked="value"
              @change="onDataChange"
              :options="options"
              :aria-describedby="ariaDescribedby"
              :name="`checkbox${name}`"
              :text-field="textField"
              :value-field="valueField"
              class="ml-4"
              stacked
            ></b-form-checkbox-group>
          </template>
        </b-form-group>
      </b-dropdown-form>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    textFloat: {
      required: true,
      type: String,
    },
    name: {
      required: false,
      type: String,
    },

    value: {
      required: false,
      type: [Array],
    },
    options: {
      required: false,
    },
    textField: {
      required: false,
    },
    valueField: {
      required: false,
    },
    valueSelectedAll: {
      required: false,
    },
  },
  emits: ["onDataChangeCheckAll", "onDataChange"],
  data() {
    return {
      valueAll: [],
      displaySelect: `Select ${this.textFloat}`,
      search: "",
      list: {
        options: [],
      },
    };
  },
  mounted() {},
  watch: {
    value(va) {
      var filteredArray = [];

      if (this.valueField) {
        filteredArray = this.options.filter((itm) => {
          return this.value.indexOf(itm[this.valueField]) > -1;
        });
      } else {
        filteredArray = this.options.filter((itm) => {
          return this.value.indexOf(itm) > -1;
        });
      }
      if (this.value.length == 0) {
        this.displaySelect = `Select ${this.textFloat}`;
      } else if (this.valueAll.length === this.value.length) {
        this.$emit("onDataChangeCheckAll", true);
        this.displaySelect = `Select All`;
      } else {
        this.$emit("onDataChangeCheckAll", false);
        if (this.valueField) {
          this.displaySelect = filteredArray
            .map((el) => el[this.textField])
            .toString();
        } else {
          this.displaySelect = filteredArray.map((el) => el).toString();
        }
      }
    },
    valueSelectedAll() {
      var filteredArray = [];
      if (this.valueField) {
        filteredArray = this.options.filter((itm) => {
          return this.value.indexOf(itm[this.valueField]) > -1;
        });
      } else {
        filteredArray = this.options.filter((itm) => {
          return this.value.indexOf(itm) > -1;
        });
      }
      if (this.value.length == 0) {
        this.displaySelect = `Select ${this.textFloat}`;
      } else if (this.valueAll.length === this.value.length) {
        this.$emit("onDataChangeCheckAll", true);
        this.displaySelect = `Select All`;
      } else {
        this.$emit("onDataChangeCheckAll", false);
        if (this.valueField) {
          this.displaySelect = filteredArray
            .map((el) => el[this.textField])
            .toString();
        } else {
          this.displaySelect = filteredArray.map((el) => el).toString();
        }
      }
      // }
    },
  },
  methods: {
    onDataChangeCheckAll(event) {
      if (event) {
        if (this.valueAll.length == 0) {
          if (this.valueField) {
            this.valueAll = this.options.map((el) => el[this.valueField]);
          } else {
            this.valueAll = this.options.map((el) => el);
          }
        }
        this.$emit("onDataChange", this.valueAll);
        this.handledisplaySelect(this.valueAll);
      } else {
        this.displaySelect = `Select ${this.textFloat}`;
        this.$emit("onDataChange", []);
        // this.valueAll = [];
      }
      this.$emit("onDataChangeCheckAll", event);
    },
    onDataChange(event) {
      if (this.valueAll.length == 0)
        if (this.valueField) {
          this.valueAll = this.options.map((el) => el[this.valueField]);
        } else {
          this.valueAll = this.options.map((el) => el);
        }
      this.handledisplaySelect(event);
      this.$emit("onDataChange", event);
    },
    handleSelectedAll(newValue) {
      if (newValue) {
        this.onDataChange(this.valueAll);
      } else if (this.options.length === this.value.length) {
        this.onDataChange([]);
      }
    },
    handleSelected(newValue) {
      let value = false;
      if (this.valueAll.length === newValue.length) value = true;

      this.onDataChangeCheckAll(value);
    },
    async handledisplaySelect(event) {
      var filteredArray = [];
      if (this.valueField) {
        filteredArray = await this.options.filter((itm) => {
          return event.indexOf(itm[this.valueField]) > -1;
        });
      } else {
        filteredArray = await this.options.filter((itm) => {
          return event.indexOf(itm) > -1;
        });
      }

      if (event.length == 0) {
        this.displaySelect = `Select ${this.textFloat}`;
      } else if (this.valueAll.length === event.length) {
        this.$emit("onDataChangeCheckAll", true);
        this.displaySelect = `Select All`;
      } else {
        this.$emit("onDataChangeCheckAll", false);
        if (this.valueField) {
          this.displaySelect = await filteredArray
            .map((el) => el[this.textField])
            .toString();
        } else {
          this.displaySelect = await filteredArray.map((el) => el).toString();
        }
      }
    },
    handleSearch() {},
    clearValue() {
      this.displaySelect = `Select ${this.textFloat}`;
      this.$emit("onDataChangeCheckAll", false);
    },
  },
};
</script>

<style scoped>
.title {
  margin-bottom: 0.4rem;
  font-weight: 600;
}
.search-bar {
  margin-left: 10px;
}
.input-group-text {
  background: white;
}
.form-group {
  margin-bottom: 0px;
}
::v-deep .btn-dropdown-checkbox {
  color: var(--font-main-color);
  border: 1px solid #e8e8e8 !important;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.4rem 0.5rem;
}
::v-deep .btn-dropdown-checkbox:first-of-type {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left !important;
}
::v-deep .btn-dropdown-checkbox svg {
  padding-left: 10px !important;
  margin-top: 3px !important;
  width: 20px;
}
::v-deep .b-dropdown-form {
  padding: 5px 12px !important;
}
::v-deep .dropdown .dropdown-menu {
  max-height: 400px;
  overflow-y: auto;
}
.display-select {
  color: #adadad;
  display: inline-block;
  margin-bottom: 6px;
  padding-top: 2px;
}
</style>
