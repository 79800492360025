<template>
  <b-form-group :label="label">
    <b-form-radio-group
      :id="id"
      v-model="val"
      :options="options"
      :name="name"
      :text-field="textField"
      :value-field="valueField"
      @change="onDataChange"
      :disabled="disabled"
    ></b-form-radio-group>
  </b-form-group>
</template>

<script>
export default {
  props: {
    label: {
      required: true,
      type: String,
    },
    id: {
      required: true,
      type: String,
    },
    options: {
      required: true,
      type: Array,
    },
    name: {
      required: true,
      type: String,
    },
    textField: {
      required: true,
      type: String,
    },
    valueField: {
      required: true,
      type: String,
    },
    value: {
      required: true,
      type: String | Number,
    },
    disabled: {
      required: false,
    },
  },
  data() {
    return {
      val: this.value,
    };
  },
  watch: {
    value(val) {
      this.val = val;
    },
  },
  methods: {
    onDataChange(evt) {
      this.$emit("onDataChange", evt);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
