import Vue from "vue";
import axios from "axios";
import apiPath from "@/plugins/api";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export const state = () => ({
  state: {
    modalAlertLogin: false,
    allRightRequestList: [],
    allRightRequestDetail: {},
    allRightRequestHistory: [],
    customerList: [],
    resCreateRequestInformation: [],
    resConfirmRequest: [],
    respExport: {},
  },
});

export const mutations = {
  setModalAlertLogin: async function (state, val) {
    state.modalAlertLogin = val;
  },
  setAllRightRequestList: async function (state, val) {
    state.allRightRequestList = val;
  },
  setAllRightRequestDetail: async function (state, val) {
    state.allRightRequestDetail = val;
  },
  setAllRightRequestHistory: async function (state, val) {
    state.allRightRequestHistory = val;
  },
  setCustomerList: async function (state, val) {
    state.customerList = val;
  },
  setResCreateRequestInformation: async function (state, val) {
    state.resCreateRequestInformation = val;
  },
  setResConfirmRequest: async function (state, val) {
    state.resConfirmRequest = val;
  },
  setRespExport: async function (state, val) {
    state.respExport = val;
  },
};

export const actions = {
  getAllRightRequestList: async ({ commit }, payload) => {
    await axios.post(`${apiPath}/DSR/request_list`, payload).then(
      async (data) => {
        if (data.data.result == 1) {
          commit("setAllRightRequestList", data.data);
        }
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  getAllRightRequestDetail: async ({ commit }, payload) => {
    await axios.get(`${apiPath}/DSR/request_detail/${payload}`).then(
      async (data) => {
        commit("setAllRightRequestDetail", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  getAllRightRequestHistory: async ({ commit }, payload) => {
    await axios.post(`${apiPath}/DSR/request_history`, payload).then(
      async (data) => {
        if (data.data.result == 1) {
          commit("setAllRightRequestHistory", data.data);
        }
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  getCustomerCreateRequestList: async ({ commit }, payload) => {
    await axios
      .post(`${apiPath}/Customer/customer_list_by_filter`, payload)
      .then(
        async (data) => {
          commit("setCustomerList", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  createRequestInformation: async ({ commit }, payload) => {
    await axios.post(`${apiPath}/DSR/created_request`, payload).then(
      async (data) => {
        commit("setResCreateRequestInformation", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  confirmRequest: async ({ commit }, payload) => {
    await axios.post(`${apiPath}/DSR/confirm_request`, payload).then(
      async (data) => {
        commit("setResConfirmRequest", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  exportAllRightRequest: async ({ commit }) => {
    await axios
      .get(`${apiPath}/DSR/export_request`, {
        responseType: "blob",
      })
      .then(
        (data) => {
          commit("setRespExport", data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
};

export default {
  state,
  actions,
  mutations,
};
