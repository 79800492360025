<template>
  <div>
    <div class="content-between">
      <div>
        <label for="tag-input" class="mb-1 main-label">
          Optional Telephone
        </label>
      </div>

      <div v-if="telephoneLimit != 0 && telephoneLimit != ''">
        <small>{{ value.length }}/{{ telephoneLimit }}</small>
      </div>
    </div>

    <div class="input-tag-container">
      <template v-if="value.length > 0">
        <template v-for="(item, index) of value">
          <div class="items-list" :key="item">
            {{ convertTelePhone(item) }}
            <font-awesome-icon
              size="sm"
              icon="times"
              class="ml-2 cursor-pointer"
              @click="removeItem(index)"
            />
          </div>
        </template>
      </template>
      <template v-else>
        <div style="color: #d8dbe0" class="pl-2">Add Optional Telephone.</div>
      </template>

      <b-button
        class="p-0 add-button"
        @click="showOptional"
        variant="custom-link"
        >{{
          telephoneLimit == 0 || value.length < telephoneLimit ? "Add" : "Edit"
        }}</b-button
      >
    </div>
    <div v-if="errorMessage" class="text-error">
      Duplicate Telephone Number.
    </div>

    <OptionalModal
      :optionalList="value"
      :add="true"
      ref="optionalModal"
      @update-value="addOptional"
      :telephoneLimit="telephoneLimit"
    />
  </div>
</template>

<script>
import OptionalModal from "@/components/report/customer/detail/components/Optional.vue";

export default {
  props: {
    type: {
      required: false,
      default: "Number",
    },
    limit: {
      required: false,
      type: Number | String,
      default: 10,
    },
    placeholder: {
      required: false,
      type: String,
      default: "Optional Telephone. (Press Enter Or Spacebar To Add)",
    },
    value: {
      required: false,
    },
  },
  components: {
    OptionalModal,
  },

  data() {
    return {
      tagValue: "",
      errorMessage: false,
      isShow: false,
    };
  },
  computed: {
    telephoneLimit() {
      return isNaN(this.limit) ? 0 : this.limit;
    },
  },
  watch: {
    value() {
      this.tagValue = "";
    },
  },
  methods: {
    addValue(e) {
      this.$v.$reset();

      this.errorMessage = false;
      if (e.keyCode == 8 && !e.target.value && this.value.length > 0)
        return this.value.splice(this.value.length - 1, 1);
      if (e.keyCode == 13 || e.keyCode == 32) {
        this.$v.tagValue.$touch();
        if (this.$v.tagValue.$error) return e.preventDefault();
        if (!e.target.value) return e.preventDefault();
        if (this.value.includes(e.target.value))
          return (this.errorMessage = true);
        this.value.push(e.target.value);
        this.tagValue = "";
        this.$emit("input", this.value);
      }
    },

    removeItem(index) {
      this.value.splice(index, 1);
      this.$emit("input", this.value);
    },
    isNumberKey(e) {
      var charCode = e.which ? e.which : e.keyCode;

      if (e.keyCode == 8 && !e.target.value && this.value.length > 0) {
        return this.addValue(e);
      }
      if (charCode == 13 || charCode == 32) return this.addValue(e);
      if (
        charCode > 31 &&
        (charCode < 43 || charCode > 57 || charCode == 46 || charCode == 47)
      ) {
        e.preventDefault();
        return;
      }
      return;
    },
    showOptional() {
      this.$refs.optionalModal.show();
    },
    addOptional(val) {
      if (val.filter((el) => el.tel).length > 0) {
        let value = val.filter((el) => el.tel).map((el) => el.tel);
        this.$emit("input", value);
      }
      this.$refs.optionalModal.hide();
    },
  },
};
</script>

<style lang="scss">
.input-tag-container {
  color: var(--font-main-color);
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
  min-height: calc(1.5em + 0.75rem + 2px);
  padding: 0.315rem;
  width: 100%;
  background: white;
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 5px;
  input {
    flex: 1;
    border: unset;
  }
  .items-list {
    white-space: nowrap;
    padding: 0 5px;
    display: flex;
    align-items: center;
    border-radius: 0.25rem;
    width: min-content;
    height: 100%;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 0%;
    background: var(--primary-color);
    color: var(--font-primary-color);
  }
}
.add-button {
  position: absolute;
  right: 25px;
}
</style>
