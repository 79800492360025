import axios from "axios";
import apiPath from "@/plugins/api";
export default {
  async GetSalesChannel(form) {
    try {
      var response = await axios.get(
        `${apiPath}/SellChannel?search=${form.search}&page=${form.page}&take=${form.take}`
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async GetSalesChannelByID(id) {
    try {
      var response = await axios.get(`${apiPath}/SellChannel/${id}`);
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async CreateSalesChannel(form) {
    try {
      var response = await axios.post(`${apiPath}/SellChannel`, form);
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async UpdatetSalesChannel(form) {
    try {
      var response = await axios.put(`${apiPath}/SellChannel`, form);
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async DeleteSalesChannel(id) {
    try {
      var response = await axios.delete(`${apiPath}/SellChannel/${id}`);
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
};
