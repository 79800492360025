<template>
  <div class="range_container">
    <label for="range" class="main-label">{{ textFloat }}</label>
    <div class="sliders_control mt-3">
      <input
        id="fromSlider"
        type="range"
        class="range"
        :value="valueFrom"
        :min="min"
        :max="max"
      />
      <input
        id="toSlider"
        type="range"
        class="range"
        :value="valueTo"
        :min="min"
        :max="max"
      />
    </div>
    <div class="form_control">
      <div class="form_control_container">
        <input
          class="form_control_container__time__input"
          type="number"
          id="fromInput"
          :value="valueFrom"
          :min="min"
          :max="max"
          disabled
        />
      </div>
      <div class="form_control_container">
        <input
          class="form_control_container__time__input text-right"
          type="number"
          id="toInput"
          :value="valueTo"
          :min="min"
          :max="max"
          disabled
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    min: {
      required: false,
      default: 0,
    },
    max: {
      required: false,
      default: 100,
    },
    textFloat: {
      required: false,
      default: "",
    },
    from: {
      required: false,
    },
    to: {
      required: false,
    },
  },
  // data() {
  //   return {
  //     from: this.min,
  //     to: this.max,
  //   };
  // },
  computed: {
    valueFrom() {
      return this.from;
    },
    valueTo() {
      return this.to;
    },
  },
  mounted() {
    this.initSlide();
  },
  methods: {
    initSlide() {
      const fromSlider = document.querySelector("#fromSlider");
      const toSlider = document.querySelector("#toSlider");
      const fromInput = document.querySelector("#fromInput");
      const toInput = document.querySelector("#toInput");
      this.fillSlider(fromSlider, toSlider, "#C6C6C6", "#25daa5", toSlider);
      this.setToggleAccessible(toSlider);

      fromSlider.oninput = () =>
        this.controlFromSlider(fromSlider, toSlider, fromInput);
      toSlider.oninput = () =>
        this.controlToSlider(fromSlider, toSlider, toInput);
      fromInput.oninput = () =>
        this.controlFromInput(fromSlider, fromInput, toInput, toSlider);
      toInput.oninput = () =>
        this.controlToInput(toSlider, fromInput, toInput, toSlider);
    },
    controlFromInput(fromSlider, fromInput, toInput, controlSlider) {
      const [from, to] = this.getParsed(fromInput, toInput);

      this.fillSlider(fromInput, toInput, "#C6C6C6", "#25daa5", controlSlider);

      if (from > to) {
        return;
      } else if (from == to) {
        fromSlider.value = to;
        fromInput.value = to;
        this.$emit("from", to);
      } else {
        fromSlider.value = from;
        this.$emit("from", from);
      }
    },

    controlToInput(toSlider, fromInput, toInput, controlSlider) {
      const [from, to] = this.getParsed(fromInput, toInput);
      this.fillSlider(fromInput, toInput, "#C6C6C6", "#25daa5", controlSlider);
      this.setToggleAccessible(toInput);
      if (from <= to) {
        toSlider.value = to;
        toInput.value = to;
        this.$emit("to", to);
      } else {
        this.$emit("to", from);
        toInput.value = from;
      }
    },

    controlFromSlider(fromSlider, toSlider, fromInput) {
      const [from, to] = this.getParsed(fromSlider, toSlider);
      this.fillSlider(fromSlider, toSlider, "#C6C6C6", "#25daa5", toSlider);
      if (from > to) {
        fromSlider.value = to;
        fromInput.value = to;
        this.$emit("from", to);
      } else {
        this.$emit("from", from);
        fromInput.value = from;
      }
    },

    controlToSlider(fromSlider, toSlider, toInput) {
      const [from, to] = this.getParsed(fromSlider, toSlider);
      this.fillSlider(fromSlider, toSlider, "#C6C6C6", "#25daa5", toSlider);
      this.setToggleAccessible(toSlider);
      if (from <= to) {
        toSlider.value = to;
        toInput.value = to;
        this.$emit("to", to);
      } else {
        toInput.value = from;
        this.$emit("to", from);
        toSlider.value = from;
      }
    },

    getParsed(currentFrom, currentTo) {
      const from = parseInt(currentFrom.value, 10);
      const to = parseInt(currentTo.value, 10);
      return [from, to];
    },

    fillSlider(from, to, sliderColor, rangeColor, controlSlider) {
      const rangeDistance = to.max - to.min;
      const fromPosition = from.value - to.min;
      const toPosition = to.value - to.min;
      controlSlider.style.background = `linear-gradient(
      to right,
      ${sliderColor} 0%,
      ${sliderColor} ${(fromPosition / rangeDistance) * 100}%,
      var(--primary-color) ${(fromPosition / rangeDistance) * 100}%,
      var(--primary-color) ${(toPosition / rangeDistance) * 100}%,
      ${sliderColor} ${(toPosition / rangeDistance) * 100}%,
      ${sliderColor} 100%)`;
    },

    setToggleAccessible(currentTarget) {
      const toSlider = document.querySelector("#toSlider");
      if (Number(currentTarget.value) <= 0) {
        toSlider.style.zIndex = 2;
      } else {
        toSlider.style.zIndex = 0;
      }
    },
  },
};
</script>

<style lang="scss">
.range_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin: 35% auto; */
}

.sliders_control {
  position: relative;
  /* min-height: 50px; */
}

.form_control {
  position: relative;
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  font-size: 24px;
  color: #635a5a;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  pointer-events: all;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #c6c6c6;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  pointer-events: all;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #c6c6c6;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb:hover {
  background: #f7f7f7;
}

input[type="range"]::-webkit-slider-thumb:active {
  box-shadow: inset 0 0 3px var(--primary-color), 0 0 9px var(--primary-color);
  -webkit-box-shadow: inset 0 0 3px var(--primary-color),
    0 0 9px var(--primary-color);
}

input.form_control_container__time__input[type="number"] {
  color: #8a8383;
  width: 50px;
  height: 30px;
  font-size: 20px;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #d8dbe0;
  //   border-radius: 0.25rem;
}

input.form_control_container__time__input[type="number"]::-webkit-inner-spin-button,
input.form_control_container__time__input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  width: 100%;
  position: absolute;
  background-color: #c6c6c6;
  pointer-events: none;
}

#fromSlider {
  height: 0;
  z-index: 1;
}
</style>
