const Mission = {
  path: "",
  name: "",
  redirect: "/mission",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/mission",
      redirect: "/mission",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Mission",
      meta: {
        requiresAuth: true,
        activeUrl: "/mission",
        parent: "mission",
        lv: 1,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/mission"),
          meta: {
            requiresAuth: true,
            activeUrl: "/mission",
            parent: "mission",
            lv: 2,
          },
        },
        {
          path: "details/:id",
          name: "Mission Detail",
          component: () => import("@/views/pages/mission/detail"),
          meta: {
            requiresAuth: true,
            isChildren: true,
            activeUrl: "/mission",
            parent: "mission",
            lv: 3,
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
export default Mission;
