import apiPath from "@/plugins/api";
import axios from "axios";
export const state = () => ({
    state: {
        setModalAlertLogin: false,
        stateProductPromotionList: null,
        stateCategoryProductAh1: null,
        stateCategoryProductAh2: null,
        stateCategoryProductAh3: null,
        stateCategoryProductAh4: null,
        statecreateProductPromotion: null,
        stateUploadProductImage: null,
        stateProductPromotionDetail: null,
        stateUpdateProductPromotion: null,
        stateDownloadTemplateProduct:null,
        stateExportProductList:null,
        stateFileProductList : null
    }
})

export const mutations = {
    setProductPromotionList: async function (state, val) {
        state.stateProductPromotionList = val;
    },
    setCategoryProductAh1: async function (state, val) {
        state.stateCategoryProductAh1 = val;
    },
    setCategoryProductAh2: async function (state, val) {
        state.stateCategoryProductAh2 = val;
    },
    setCategoryProductAh3: async function (state, val) {
        state.stateCategoryProductAh3 = val;
    },
    setCategoryProductAh4: async function (state, val) {
        state.stateCategoryProductAh4 = val;
    },
    setcreateProductPromotion: async function (state, val) {
        state.statecreateProductPromotion = val;
    },
    setUploadProductImage: async function (state, val) {
        state.stateUploadProductImage = val;
    },
    setProductPromotionDetail: async function (state, val) {
        state.stateProductPromotionDetail = val;
    },
    setUpdateProductPromotion: async function (state, val) {
        state.stateUpdateProductPromotion = val;
    },
    setDownloadTemplateProduct:async function (state, val) {
        state.stateDownloadTemplateProduct = val;
    },
    setExportProductList: async function (state, val) {
        state.stateExportProductList = val;
    },
    setFileProductList: async function (state, val) {
        state.stateFileProductList = val;
    },
}

export const actions = {
    getProductPromotionList: async ({ commit }, payload) => {
        await axios.post(`${apiPath}/ProductMenu/product_list`, payload)
            .then(async data => {
                commit('setProductPromotionList', data.data);
            }, (error) => {
                const code = parseInt(error.response && error.response.status);
                if (code === 401) {
                    commit('setModalAlertLogin', true);
                }
            });
    },
    getCategoryProductAh1: async ({ commit }, payload) => {
        await axios.post(`${apiPath}/ProductMenu/get_category_ah1`, payload)
            .then(async data => {
                commit('setCategoryProductAh1', data.data);
            }, (error) => {
                const code = parseInt(error.response && error.response.status);
                if (code === 401) {
                    commit('setModalAlertLogin', true);
                }
            });
    },
    getCategoryProductAh2: async ({ commit }, payload) => {
        await axios.post(`${apiPath}/ProductMenu/get_category_ah2`, payload)
            .then(async data => {
                commit('setCategoryProductAh2', data.data);
            }, (error) => {
                const code = parseInt(error.response && error.response.status);
                if (code === 401) {
                    commit('setModalAlertLogin', true);
                }
            });
    },
    getCategoryProductAh3: async ({ commit }, payload) => {
        await axios.post(`${apiPath}/ProductMenu/get_category_ah3`, payload)
            .then(async data => {
                commit('setCategoryProductAh3', data.data);
            }, (error) => {
                const code = parseInt(error.response && error.response.status);
                if (code === 401) {
                    commit('setModalAlertLogin', true);
                }
            });
    },
    getCategoryProductAh4: async ({ commit }, payload) => {
        await axios.post(`${apiPath}/ProductMenu/get_category_ah4`, payload)
            .then(async data => {
                commit('setCategoryProductAh4', data.data);
            }, (error) => {
                const code = parseInt(error.response && error.response.status);
                if (code === 401) {
                    commit('setModalAlertLogin', true);
                }
            });
    },
    createProductPromotion: async ({ commit }, payload) => {
        await axios.post(`${apiPath}/ProductMenu/create_product`, payload)
            .then(async data => {
                commit('setcreateProductPromotion', data.data);
            }, (error) => {
                const code = parseInt(error.response && error.response.status);
                if (code === 401) {
                    commit('setModalAlertLogin', true);
                }
            });
    },
    uploadProductImage: async ({ commit }, payload) => {
        await axios.post(`${apiPath}/ProductMenu/upload_image`, payload)
            .then(async data => {
                commit('setUploadProductImage', data.data);
            });
    },
    getProductPromotionDetail: async ({ commit }, payload) => {
        await axios.get(`${apiPath}/ProductMenu/get_product_detail/${payload}`)
            .then(async data => {
                commit('setProductPromotionDetail', data.data);
            }, (error) => {
                const code = parseInt(error.response && error.response.status);
                if (code === 401) {
                    commit('setModalAlertLogin', true);
                }
            });
    },
    updateProductPromotion: async ({ commit }, payload) => {
        await axios.put(`${apiPath}/ProductMenu/update_product_detail`, payload)
            .then(async data => {
                commit('setUpdateProductPromotion', data.data);
            }, (error) => {
                const code = parseInt(error.response && error.response.status);
                if (code === 401) {
                    commit('setModalAlertLogin', true);
                }
            });
    },
    downloadTemplateProduct: async ({ commit }) => {
        await axios({
            url : `${apiPath}/ProductMenu/product_template`,
            method : "get",
            responseType: "blob",
        })
        .then(async data => {
          commit('setDownloadTemplateProduct',data.data);
        },(error) => { 
            const code = parseInt(error.response && error.response.status);
                if (code === 401) {
                    commit('setModalAlertLogin',true);
                }
            }
        );
    },
    exportProductList: async ({ commit }, payload) => {
        await axios.post(`${apiPath}/ProductMenu/export_product_list`, payload,
            {
                responseType: 'blob'
            })
            .then((data) => {
                commit('setExportProductList', data);
            }, (error) => {
                const code = parseInt(error.response && error.response.status);
                if (code === 401) {
                    commit('setModalAlertLogin', true);
                }
            })
    },
    importFileProductList: async ({ commit } , payload ) => {
        await axios.post(`${apiPath}/ProductMenu/import_product`, payload)
        .then(async data => {
            commit('setFileProductList',data.data);
        },(error) => { 
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
                commit('setModalAlertLogin',true);
            }
        });
    },
}

export default {
    state,
    actions,
    mutations
}