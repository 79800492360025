import connectAPI from '../connectAPISalesChannel/connectAPI'

const moduleSalesChannel = {
  state: {
    stateGetSalesChannel: null,
    stateGetSalesChannelByID : null,
    stateCreateSalesChannel: null,
    stateUpdatetSalesChannel: null,
    stateDeleteSalesChannel: null
  },
  mutations: {
    mutationGetSalesChannel (state, data) {
      state.stateGetSalesChannel = data
    },
    mutationSalesChannelByID (state, data) {
      state.stateGetSalesChannelByID  = data
    },
    mutationCreateSalesChannel (state, data) {
      state.stateCreateSalesChannel = data
    },
    mutationUpdatetSalesChannel (state, data) {
      state.stateUpdatetSalesChannel = data
    },
    mutationDeleteSalesChannel (state, data) {
      state.stateDeleteSalesChannel = data
    },
  },
  actions: {
    async ActionGetSalesChannel (contaxt, data) {
      const dataFromAxios = await connectAPI.GetSalesChannel(data)
      await contaxt.commit('mutationGetSalesChannel', dataFromAxios)
    },
    async ActionGetSalesChannelByID (contaxt, data) {
      const dataFromAxios = await connectAPI.GetSalesChannelByID(data)
      await contaxt.commit('mutationSalesChannelByID', dataFromAxios)
    },
    async ActionCreateSalesChannel (contaxt, data) {
      const dataFromAxios = await connectAPI.CreateSalesChannel(data)
      await contaxt.commit('mutationCreateSalesChannel', dataFromAxios)
    },
    async ActionUpdatetSalesChannel (contaxt, data) {
      const dataFromAxios = await connectAPI.UpdatetSalesChannel(data)
      await contaxt.commit('mutationUpdatetSalesChannel', dataFromAxios)
    },
    async ActionDeleteSalesChannel (contaxt, data) {
      const dataFromAxios = await connectAPI.DeleteSalesChannel(data)
      await contaxt.commit('mutationDeleteSalesChannel', dataFromAxios)
    },
  }
}

export default moduleSalesChannel
