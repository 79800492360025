import PATH from "../path";

function configRoutes() {
  return [
    {
      path: "/",
      name: "Login",
      component: () => import("@/views/pages/Login"),
    },
    {
      path: "/azureauth/login",
      name: "Azure Auth callback",
      component: () => import("@/views/pages/AzureAuthLogin"),
    },
    {
      path: "/home",
      name: "Home",
      redirect: "/customer-analysis",
      component: () => import("@/containers/TheContainer"),
      meta: { requiresAuth: true, lv: 1 },
      children: [...PATH],
    },

    {
      path: "/500",
      name: "Page500",
      component: () => import("@/views/pages/Page500"),
    },
    {
      path: "*",
      name: "Page404",
      component: () => import("@/views/pages/Page404"),
    },
    {
      path: "/403",
      name: "Page403",
      component: () => import("@/views/pages/Page403"),
    },
  ];
}
export default configRoutes;
