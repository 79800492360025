// import axios from "axios";
import { $report } from "@/services/axios";
import moment from "moment";
export const state = {
  dateRange: null,
  syncFilterValue: null,
  filter: {
    customData: null,
    dateType: 3,
    from: moment().subtract(1, "year").format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
    product: [],
    branch: [],
    channel: [],
    age: [],
    gender: [],
    tier: [],
  },
  options: {
    memberTiers: [],
    channelTypes: [],
    branches: [],
    ageGroups: [],
    genderModels: [],
    products: [],
    promotions: [],
    isLoading: false,
  },
};

export const mutations = {
  setRange: async function (state, val) {
    state.dateRange = val;
  },
  setFilterValue: async function (state, { key, value }) {
    state.filter[key] = value;
  },
  setDefaultFilter: function (state) {
    state.filter = {
      customData: null,
      dateType: 2,
      from: null,
      to: null,
      product: [],
      branch: [],
      channel: [],
      age: [],
      gender: [],
      tier: [],
    };
  },
  setOptions: function (state, value) {
    state.options = value;
  },
};

export const actions = {
  getFilterDate: async ({ commit, state }, id) => {
    try {
      if (state.dateRange) return state.dateRange;
      const result = await $report(`/dashboard/Filter/max_min_date/${id}`);
      commit("setRange", result.data.detail);

      return result.data.detail;
    } catch (error) {
      console.error(error.message, "Filter Date Error");
    }
  },
  getFilterOptions: async ({ commit, state }) => {
    try {
      if (state.options.isLoading) return state.options;
      const result = await $report(`/RevenueReport/CustomFilter`);
      result.data.dateType = [
        { text: "This Week", value: 1 },
        { text: "This Month", value: 2 },
        { text: "This Year", value: 3 },
        { text: "Custom", value: 0 },
      ];
      result.data.isLoading = true;

      commit("setOptions", result.data);

      return result.data.detail;
    } catch (error) {
      console.error(error.message, "Filter Date Error");
    }
  },
  setFilter: async function ({ commit, state }, { key, value }) {
    commit("setFilterValue", { key, value });
  },
  clearFilter: async function ({ commit, state }) {
    commit("setDefaultFilter");
  },
};
export const getters = {
  filter: (state) => {
    return state.filter;
  },
  options: (state) => {
    return state.options;
  },
};
export default {
  state,
  actions,
  mutations,
  getters,
};
