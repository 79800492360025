<template>
  <div>
    <div>
      <InputSelect
        :title="customText"
        name="date"
        class="mt-2 mb-1"
        :value="type"
        :options="dateType"
        valueField="value"
        textField="text"
        @input="updateType"
      >
        <template v-slot:option-first>
          <b-form-select-option value="" disabled
            >-- Select Date Filter --</b-form-select-option
          >
        </template>
      </InputSelect>
    </div>
    <div v-if="type == 0">
      <InputDatePickerFilter
        :textFloat="`${customText} From`"
        name="date-from"
        ref="date-from"
        :value="from"
        @input="updateFrom"
      />
    </div>

    <div v-if="type == 0">
      <InputDatePickerFilter
        :textFloat="`${customText} To`"
        name="date-to"
        ref="date-to"
        :value="to"
        @input="updateTo"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      required: true,
    },
    customText: { required: false, default: "Date" },
    from: {
      required: false,
    },
    to: {
      required: false,
    },
    type: {
      required: false,
    },
    useStore: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      dateType: [
        { text: "This Week", value: 1 },
        { text: "This Month", value: 2 },
        { text: "This Year", value: 3 },
        { text: "Custom", value: 0 },
      ],
      range: [],
      valueDateType: null,
    };
  },
  computed: {
    // ...mapGetters(["filter"]),
  },

  created() {
    // this.getRangeTransaction();
  },
  methods: {
    async getRangeTransaction() {
      const date = await this.$store.dispatch("getFilterDate", this.id);

      this.range = date.map((el) => {
        return { max: el.max_date, min: el.min_date };
      })[0];
    },
    updateFrom(val) {
      this.$emit("update:from", val);
      if (this.useStore)
        this.$store.dispatch("setFilter", { key: "from", value: val });
    },
    updateTo(val) {
      this.$emit("update:to", val);
      if (this.useStore)
        this.$store.dispatch("setFilter", { key: "to", value: val });
    },
    updateType(val) {
      this.$emit("update:type", val);
      if (this.useStore)
        this.$store.dispatch("setFilter", {
          key: "dateType",
          value: val,
        });
    },
  },
};
</script>

<style></style>
