import Vue from "vue";
import _ from "lodash";
import Pagination from "@/components/Pagination.vue";
import Footer from "@/components/FooterAction.vue";
import SideBarFilter from "@/components/SideBarFilter.vue";
import StatPanel from "@/components/StatPanel";
import HeaderPanel from "@/components/HeaderPanel.vue";
// import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import VueClipboard from "vue-clipboard2";

VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);

const requireComponent = require.context(
  "@/components/inputs",
  true,
  /[A-Z]\w+\.vue$/
);
const getGlobalComponents = () => {
  Vue.component("FooterAction", Footer);
  Vue.component("SideBarFilter", SideBarFilter);
  Vue.component("Pagination", Pagination);
  Vue.component("HeaderPanel", HeaderPanel);
  Vue.component("StatPanel", StatPanel);
  requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName);
    const componentName = _.upperFirst(
      _.camelCase(
        fileName
          .split("/")
          .pop()
          .replace(/\.\w+$/, "")
      )
    );

    Vue.component(componentName, componentConfig.default || componentConfig);
  });
};
export default getGlobalComponents;
