const Product = {
  path: "",
  name: "",
  redirect: "/product",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/product",
      redirect: "/product",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Product",
      meta: {
        requiresAuth: true,
        activeUrl: "/product",
        parent: "/product",

        lv: 1,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/productPromotion"),
          meta: {
            requiresAuth: true,
            activeUrl: "/product",
            parent: "/product",

            lv: 2,
          },
        },
        {
          path: "detail/:id",
          name: "Product Detail",
          component: () =>
            import("@/views/pages/productPromotion/detail/detail"),
          meta: {
            requiresAuth: true,
            isChildren: true,
            activeUrl: "/product",
            parent: "/product",

            lv: 3,
          },
        },
        {
          path: "imporfile",
          name: "Import Product",
          component: () =>
            import("@/views/pages/productPromotion/detail/importFile"),
          meta: {
            requiresAuth: true,
            isChildren: true,
            activeUrl: "/product",
            parent: "/product",

            lv: 3,
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
export default Product;
