<template>
  <div class="mb-2">
    <div class="main-label" v-if="textFloat">
      {{ textFloat }} <span v-if="isRequired" class="text-danger">*</span>
    </div>
    <div :class="['input-container', { error: isValidate }]">
      <datetime
        :type="type"
        placeholder="Please Select Date"
        :format="format || 'dd/MM/yyyy'"
        value-zone="local"
        :value="value"
        :ref="`ref${name}`"
        :max-datetime="maxDatetime"
        :min-datetime="minDatetime"
        @input="$emit('input', $event)"
        @close="$emit('close', $event)"
        :disabled="disabled"
      >
      </datetime>
      <div
        class="icon-primary text-right"
        @click="$refs[`ref${name}`].isOpen = true"
      >
        <font-awesome-icon
          icon="calendar-alt"
          class="pointer color-primary"
          color="#B41BB4"
        />
      </div>
    </div>
    <template v-if="v">
      <div v-if="v.$error" class="text-danger">Please select date.</div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    textFloat: {
      required: true,
      type: String,
    },
    name: {
      required: false,
      type: String,
    },
    value: {
      required: false,
      type: String,
    },
    minDatetime: {
      required: false,
      type: String,
    },
    maxDatetime: {
      required: false,
      type: String,
    },
    format: {
      required: false,
    },
    isValidate: {
      required: false,
    },
    isRequired: {
      required: false,
    },
    v: {
      required: false,
    },
    type: {
      required: false,
      default: "date",
      type: String,
    },
    disabled: {
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped>
.title {
  margin-bottom: 0.4rem;
  font-weight: 600;
}
</style>
