var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"div-input"},[_c('div',{class:['input-custom', { error: _vm.isValidate }]},[(_vm.textFloat)?_c('label',[_vm._v(" "+_vm._s(_vm.textFloat)+" "),(_vm.isRequired)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[(_vm.showFileName)?_c('div',{staticClass:"w-100 display-only"},[_c('input',{ref:"file",attrs:{"type":_vm.type,"placeholder":_vm.placeholder,"name":_vm.name,"required":_vm.required,"size":_vm.size,"disabled":""},domProps:{"value":_vm.fileNameDisplay}}),(!_vm.noDelete && _vm.fileNameDisplay && !_vm.disabled)?_c('font-awesome-icon',{staticClass:"text-secondary delete-icon pointer",attrs:{"icon":"times-circle"},on:{"click":_vm.deleteImage}}):_vm._e()],1):_vm._e(),(!_vm.disabled)?_c('label',{staticClass:"mb-0 btn-main",attrs:{"size":_vm.size}},[_c('input',{ref:"input",attrs:{"multiple":_vm.isMultiple,"type":"file","required":_vm.required,"name":_vm.name,"id":_vm.id,"accept":_vm.format === 'image'
                ? _vm.type.image
                : _vm.format === 'font'
                ? '.ttf, .otf,.woff'
                : _vm.format == 'excel'
                ? _vm.type.excel
                : ''},on:{"change":_vm.handleFileChange}}),_c('font-awesome-icon',{staticClass:"bg-icon mr-2",attrs:{"icon":"file-upload","color":"white","size":_vm.size}}),_vm._v("Choose file ")],1):_vm._e()])]),_c('p',{staticClass:"detail-format"},[_vm._v(_vm._s(_vm.text))]),(_vm.showWarningMessage)?_c('p',{staticClass:"detail-format text-danger"},[_vm._v(" "+_vm._s(_vm.warningMessage)+" ")]):_vm._e(),(_vm.v && _vm.v.$error)?_c('div',{staticClass:"mt-1"},[(_vm.v.required == false)?_c('span',{staticClass:"text-error"},[_vm._v("This field can’t be empty")]):_vm._e()]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }