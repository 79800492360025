const AllRightRequest = {
  path: "",
  name: "",
  redirect: "/allrightrequest",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/allrightrequest",
      redirect: "/allrightrequest",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Right Request",
      meta: {
        requiresAuth: true,
        activeUrl: "/allrightrequest",
        parent: "/allrightrequest",

        lv: 1,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/allRightRequest"),
          meta: {
            requiresAuth: true,
            activeUrl: "/allrightrequest",
            parent: "/allrightrequest",

            lv: 2,
          },
        },
        {
          path: "detail/:id",
          name: "Right Request Detail",
          component: () => import("@/views/pages/allRightRequest/Detail"),
          meta: {
            requiresAuth: true,
            isChildren: true,
            activeUrl: "/allrightrequest",
            parent: "/allrightrequest",

            lv: 3,
          },
        },
        {
          path: "/create",
          name: "Create Request Information",
          component: () =>
            import(
              "@/views/pages/allRightRequest/createAllRightRequest/CreateAllRightRequest"
            ),
          meta: {
            requiresAuth: true,

            isChildren: true,
            activeUrl: "/allrightrequest",
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
export default AllRightRequest;
