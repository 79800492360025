import Vue from "vue";
import { store } from "../../store";

export default async function RoutePermission({ next, to, redirect }) {
  if (!store.state.permission.Permission) {
    await store.dispatch("getPermission");
  }

  if (!Vue.$cookies.isKey("back_office_admin_token"))
    return next({
      path: "/",
      query: {
        nextUrl: to.fullPath,
      },
    });

  return next();
}
