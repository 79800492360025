import axios from 'axios'
import apiPath from "@/plugins/api";
export default {
  async GetPrivilegeCodeList(form) {
    try {
      var response = await axios.post(`${apiPath}/Privilege/list`, form)
      return response.data
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response
    }
  },
  async GetPrivilegeCodeByID(id) {
    try {
      var response = await axios.get(`${apiPath}/Privilege/${id}`)
      return response.data
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response
    }
  },
  async CreatePrivilegeCode(form) {
    try {
      var response = await axios.post(`${apiPath}/Privilege`, form)
      return response.data
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response
    }
  },
  async UpdatePrivilegeCode(form) {
    try {
      var response = await axios.put(`${apiPath}/Privilege/${form.id}`, form)
      return response.data
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response
    }
  },
  async GetPrivilegeCondition(form) {
    try {
      var response = await axios.post(`${apiPath}/Privilege/condition/${form.id}`, form)
      return response.data
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response
    }
  },
  async GetUsedPrivilegeReport(filter) {
    try {
      var response = await axios.post(`${apiPath}/Privilege/user_privilege_list`, filter)
      return response.data
    } catch (error) {
      return error.response
    }
  },
  async ExportPrivilegeReport(filter) {
    try {
      var response = await axios.post(`${apiPath}/Privilege/export_customer_privilege_list`, filter, {
        responseType: 'blob'
      })
      return response
    } catch (error) {
      return error.response
    }
  }
}
