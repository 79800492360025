import connectAPI from '../connectAPIVideo/connectAPI'

const moduleVideo = {
  state: {
    stateGetVideoList: null,
    stateGetVideoByID: null,
    stateGetVideoMainCategory: null,
    stateGetVideoSubCategory: null,
    stateCreateVideo: null,
    stateUpdateVideo: null,
    stateDeleteVideo: null,
  },
  mutations: {
    mutationGetVideoList (state, data) {
      state.stateGetVideoList = data
    },
    mutationGetVideoByID (state, data) {
      state.stateGetVideoByID = data
    },
    mutationGetVideoMainCategory (state, data) {
      state.stateGetVideoMainCategory = data
    },
    mutationGetVideoSubCategory (state, data) {
      state.stateGetVideoSubCategory = data
    },
    mutationCreateVideo (state, data) {
      state.stateCreateVideo = data
    },
    mutationUpdateVideo (state, data) {
      state.stateUpdateVideo = data
    },
    mutationDeleteVideo (state, data) {
      state.stateDeleteVideo = data
    },
  },
  actions: {
    async ActionGetVideoList (contaxt, data) {
      const dataFromAxios = await connectAPI.GetVideoList(data)
      await contaxt.commit('mutationGetVideoList', dataFromAxios)
    },
    async ActionGetVideoByID (contaxt, id) {
      const dataFromAxios = await connectAPI.GetVideoByID(id)
      await contaxt.commit('mutationGetVideoByID', dataFromAxios)
    },
    async ActionGetVideoMainCategory (contaxt, data) {
      const dataFromAxios = await connectAPI.GetVideoMainCategory(data)
      await contaxt.commit('mutationGetVideoMainCategory', dataFromAxios)
    },
    async ActionGetVideoSubCategory (contaxt, data) {
      const dataFromAxios = await connectAPI.GetVideoSubCategory(data)
      await contaxt.commit('mutationGetVideoSubCategory', dataFromAxios)
    },
    async ActionCreateVideo (contaxt, data) {
      const dataFromAxios = await connectAPI.CreateVideo(data)
      await contaxt.commit('mutationCreateVideo', dataFromAxios)
    },
    async ActionUpdateVideo (contaxt, data) {
      const dataFromAxios = await connectAPI.UpdateVideo(data)
      await contaxt.commit('mutationUpdateVideo', dataFromAxios)
    },
    async ActionDeleteVideo (contaxt, data) {
      const dataFromAxios = await connectAPI.DeleteVideo(data)
      await contaxt.commit('mutationDeleteVideo', dataFromAxios)
    },
  }
}
export default moduleVideo
