const Segment = {
  path: "",
  name: "",
  redirect: "/segment",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/segment",
      redirect: "/segment",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Segment",
      meta: {
        requiresAuth: true,
        activeUrl: "/segment",
        parent: "segment",
        lv: 1,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/segment/Index"),
          meta: {
            requiresAuth: true,
            activeUrl: "/segment",
            parent: "segment",
            lv: 2,
          },
        },
        {
          path: "details/:id",
          name: "Segment Detail",
          component: () => import("@/views/pages/segment/Details"),
          meta: {
            requiresAuth: true,
            isChildren: true,
            activeUrl: "/segment",
            parent: "segment",
            lv: 3,
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
export default Segment;
